import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreatorGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // TODO: Check user access here.
    const comic: string = next.paramMap.get('comic');
    const isNewComic: boolean = next.queryParams['new_comic'] == "true";
    return comic != '/' || (comic == '/' && !isNewComic);
  }
}
