import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiMap } from '../constants/apiUrlMapper';
import * as firebase from "firebase/app";


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  private callFirebaseFunction(functionName: string) {
    return async (data: any = {}): Promise<any> => {
      // Uncomment if Firebase functions are being used
      // const firebaseFunction = firebase.functions().httpsCallable(functionName);
      // return firebaseFunction(data);
    };
  }

  filterValidData(data: { [key: string]: any }): { [key: string]: any } {
    return Object.keys(data).reduce((filtered, key) => {
      const value = data[key];
      if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
        filtered[key] = value;
      }
      return filtered;
    }, {} as { [key: string]: any });
  }

  private callRestApi(method: string, url: string) {
    return async (data: any = {}): Promise<any> => {
      const authToken = await firebase.auth().currentUser.getIdToken(); // Retrieve the Firebase JWT token
  
      const headers = new HttpHeaders({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      });
      let response: any;

      switch (method) {
        case 'GET':
          data = this.filterValidData(data);
          const queryParams = new URLSearchParams(data).toString();
          url = `${url}?${queryParams}`;
          response =  await this.http.get(url, { headers }).toPromise();
          break;
        case 'POST':
          response =  await this.http.post(url, data, { headers }).toPromise();
          break;
        case 'PUT':
          data = this.filterValidData(data);
          response =  await this.http.put(url, data, { headers }).toPromise();
          break;
        case 'DELETE':
          data = this.filterValidData(data);
          const deleteParams = new HttpParams({ fromObject: data });
          response =  await this.http.delete(url, { params: deleteParams, headers }).toPromise();
          break;
        default:
          throw new Error(`Unsupported method: ${method}`);
      }
      if (response && response.result) {
        response.data = response.result;
        delete response.result;
      }
      return response;
    };
  }

  public async send<T = any>(functionName: string): Promise<(data?: any) => Promise<T>> {
    if (environment.useFirebaseFunctions) {
      return this.callFirebaseFunction(functionName);
    } else {
      const { url, method } = this.getApiDetails(functionName);
      return this.callRestApi(method, url);
    }
  }

  private getApiDetails(functionName: string): { method: string, url: string } {
    const apiUrlMapper = apiMap;
    return {
      url: environment.API_BASE_URL + apiUrlMapper[functionName].url,
      method: apiUrlMapper[functionName].method
    };
  }
}