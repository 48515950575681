import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoggedOutModalComponent } from '../logged-out-modal/logged-out-modal.component';
import { EventService } from './event.service';
import { LoggedOutModalData, ModalConfig } from '../interfaces/user.interface';
import { ActionSheetModalComponent } from '../action-sheet-modal/action-sheet-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  bsModalRef: BsModalRef;
  actionSheetBsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private eventService: EventService,
  ) {
    // Listening for the showLoggedOutModalEvent event
    this.eventService.showLoggedOutModalEvent.subscribe((data) => {
      this.showLoggedOutModal(data);
    });
  }

  public showLoggedOutModal(data: LoggedOutModalData) {
    if (this.bsModalRef) return; // Modal already opened

    let isMobile = false;
    if (window.innerWidth <= 991) {
      isMobile = true;
    }
    this.bsModalRef = this.modalService.show(LoggedOutModalComponent, {
      initialState: { modalData: data },
      class: `logout-modal-layout modal-sm${isMobile ? '-mobile' : ''}`,
      ignoreBackdropClick: true
    });
  }

  public showActionSheetModal(data: ModalConfig) {
    if (this.actionSheetBsModalRef) return; // Modal already opened

    let isMobile = false;
    if (window.innerWidth <= 991) {
      isMobile = true;
    }
    this.actionSheetBsModalRef = this.modalService.show(ActionSheetModalComponent, {
      initialState: { modalConfig: data },
      class: `modal-md ${isMobile ? 'mobile-modal' : ''}`,
      ignoreBackdropClick: true
    });

    // Listen to the close event emitted by the ActionSheetModalComponent
    this.actionSheetBsModalRef.content.close && this.actionSheetBsModalRef.content.close.subscribe(() => {
      this.actionSheetBsModalRef.hide();
      this.actionSheetBsModalRef = null;
    });
  }
}
