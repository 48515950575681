import { Component, OnInit, PLATFORM_ID, Inject, TemplateRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as PostActions from '../redux/action';
import { Store } from '@ngrx/store';
import { Post } from '../redux/model';
import { KeyValue, isPlatformBrowser, formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ComicService } from '../services/comic.service';
import { UserService } from '../services/user.service';
import { ApiService } from '../services/api.service';
import { API_KEYS } from '../constants/common.constants';

interface AppState {
  post: Post;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isLoading: boolean;
  userForm: FormGroup;
  preparedData: any;
  preparedTotals: any;
  numberOfDays: number;
  errorMsg: string;
  minDate: Date;
  maxDate: Date;
  update: boolean = false;
  err: any;
  urlSubscribe: Subscription;
  IAPModalRef: any;
  showDate: any;
  showTable: boolean = false;
  giftDataSingleDate: any = [];
  IAParray: any = [];
  dashboardData: any = [];
  giftData: any;
  showDateInGift: string;
  seriesData: any = [];
  hasData: boolean;
  admins: any = [];
  allSeries: any = [];
  public obj = {};
  values: unknown[];
  isAnalysts: boolean;
  promise2: Promise<unknown>;
  promise3: Promise<unknown>;
  formattedSeries: string;
  noOfRows: any;
  dashboardData2: unknown[];
  seriesNames: Object;
  arrayObj: any = [];
  promise4: Promise<unknown>;
  promise5: Promise<unknown>;
  objArray1: any[];
  currentDate: Date;
  singleDateTable: number = 0;
  newObj: any = [];
  getSeriesData: any = [];
  series: any = '';
  testData: any = [];
  userName: string;

  constructor(
    public readonly comicService: ComicService,
    private readonly router: Router,
    public authService: AuthService,
    private readonly fb: FormBuilder,
    @Inject(PLATFORM_ID) private platform: object,
    private modalService: BsModalService,
    private store: Store<AppState>,
    private readonly userService: UserService,
    private apiService: ApiService
  ) {
    this.getUserDetail();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    this.currentDate = new Date();
    this.maxDate = this.currentDate;
    this.store.dispatch(new PostActions.EditText('Dashboard'));
    this.userForm = this.fb.group({
      dateFrom: [this.currentDate],
      dateTo: [this.currentDate],
      seriesName: ''
    });
    this.getDirectoryData();
    this.userForm.valueChanges.subscribe((value) => {
      if (value.dateTo < value.dateFrom) {
        this.err = 'Invalid date'
      } else {
        this.err = ''
      }
    })
    let utcString = new Date().toDateString().slice(0, new Date().toDateString().length - 5);
    this.preparedData = {
      "1654473600000": {
        'dateTime': new Date(),
        'utcTime': utcString.slice(0, 3) + "," + utcString.slice(3, utcString.length),
        'signups': 0,
        // 'anonymousSignups': 0,
        'returningUsers': 0,
        'app': 0,
        'email': 0,
        'sms': 0,
        'dau': 0,
        'pageviews': '0%',
        'webPageviews': '0%',
        'iOSPageviews': '0%',
        'androidPageviews': '0%',
        'IAP': 0,
        'giftItem': '',
        'totalAccepted': 0,
        'influencePointConsumed': 0,
        'influencePointEarned': 0,
        'totalSent': 0,
      }
    }
    this.preparedTotals = {
      signups: 0,
      // anonymousSignups: 0,
      returningUsers: 0,
      dau: 0,
      pageviews: 0,
      webPageviews: 0,
      iOSPageviews: 0,
      androidPageviews: 0,
      influencePurchases: 0,
    };
  }

  private async getUserDetail() {
    const username = await this.userService.getUserDetails();

    this.userName = username && username.data && username.data.data.displayName;
  }
  public getDirectoryData() {
    const user = firebase.auth().currentUser;
    this.comicService.getComicTitle().subscribe(res => {
      this.allSeries.push('All');
      this.seriesNames = this.comicService.getFormattedSeriesNames(res);
      for (let value of res.tinyview.roles) {
        if (value.analysts && value.analysts.length > 0) {
          for (let item = 0; item < value.analysts.length; item++) {
            if (user) {
              if (user.phoneNumber == value.analysts[item]) {
                this.admins.push('analysts');
                break;
              }
            }
          };
        }
      }
      for (var key in res) {
        this.allSeries.push(res[key].title);
        if (res[key].roles) {
          res[key].roles.map((i) => {
            if (i.admins && i) {
              i.admins.map((admin) => {
                if (admin == user.phoneNumber) {
                  this.admins.push(res[key].title);
                }
              })
            }
            if (i.analysts && i) {
              i.analysts.map((admin) => {
                if (admin == user.phoneNumber) {
                  this.admins.push(res[key].title);
                }
              })
            }
          });
        }
      }
      let uniqueChars = [...new Set(this.admins)];
      for (var key in res) {
        for (let a in uniqueChars) {
          if (res[key].title == this.admins[a]) {
            if (this.admins[a] == 'Tinyview') {
              this.seriesData = this.allSeries;
            } else {
              this.seriesData.push(this.admins[a]);
            }
          }
        }
      }
      this.seriesData.includes('All') ? this.userForm.get('seriesName').setValue('All') : this.userForm.get('seriesName').setValue(this.seriesData[0]);
      this.seriesData.sort(function (a, b) {
        var textA = a;
        var textB = b;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if (this.seriesData && (!this.seriesData.length || (this.seriesData.length && !this.seriesData.includes("Tinyview")))) {
        this.router.navigate(['']);
      }
    });
  }

  public onSubmit(): void {
    this.currentDate = new Date();
    const dateFrom = this.userForm.value.dateFrom;
    const dateTo = this.userForm.value.dateTo;
    if (dateFrom == 0 || dateTo == 0) {
      this.getData(new Date(), new Date());
    } else {
      this.getData(new Date(dateFrom), new Date(dateTo));
    }
  }

  private async getData(dateFrom: Date, dateTo: Date) {
    this.isLoading = true;
    this.preparedData = {};
    this.preparedTotals = {
      signups: 0,
      // anonymousSignups: 0,
      returningUsers: 0,
      app: 0,
      email: 0,
      sms: 0,
      dau: 0,
      pageviews: 0,
      webPageviews: 0,
      iOSPageviews: 0,
      androidPageviews: 0,
      influencePurchases: 0,
      totalSent: 0,
      totalAccepted: 0,
      influencePointConsumed: 0,
      influencePointEarned: 0,
      IAP: 0
    };

    this.numberOfDays = 0;
    this.getSeries(this.userForm.value.seriesName);
    // from: is lastest date, to: is earliest date
    const data = { from: this.formatDate(dateTo), to: this.formatDate(dateFrom), forceUpdate: this.update }
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    const myDateTo = new Date(dateTo);
    const date_To = formatDate(myDateTo, format, locale);
    const myDateFrom = new Date(dateFrom);
    const date_From = formatDate(myDateFrom, format, locale);
    this.promise2 = new Promise(async (resolve, reject) => {
      const getDashboardData = await this.apiService.send(API_KEYS.GET_DASHBOARD_DATA);
      getDashboardData(data).then((result) => {
        this.values = Object.values(result.data);
        this.series = '';
        for (let i in this.seriesNames) {
          if (this.seriesNames[i] == this.userForm.value.seriesName) {
            this.series = i
          }
        }
        if (this.series) {
          this.values.map((f => {
            const g = Object.entries(f['seriesPageViews'].series).filter(([key]) => key.includes(this.series));
            if (g.length) {
              f['series'] = g[0][1]
            }
          }))

          this.values.map(L => {
            if (L['seriesPageViews'].series[this.series]) {
              L['iOSPageviews'] = L['series'].iOSPageviews;
              L['pageviews'] = L['series'].pageviews;
              L['webPageviews'] = L['series'].webPageviews;
              L['androidPageviews'] = L['series'].androidPageviews;
            } else {
              L['iOSPageviews'] = 0;
              L['pageviews'] = 0;
              L['webPageviews'] = 0;
              L['androidPageviews'] = 0;
            }
          })
        }
        resolve(this.values);
        this.isLoading = false;
      }).then(() => {
        resolve(this.values);
      }, err => {
        this.isLoading = false;
        this.errorMsg = 'Server encountered some problem. Please try again.';
      })
    });

    this.promise3 = new Promise(async (resolve, reject) => {
      const getDashboardInviteData = await this.apiService.send(API_KEYS.GET_DASHBOARD_INVITE_DATA);
      getDashboardInviteData({
        "from": date_From,
        "to": date_To,
      }).then((res) => {
        resolve(res.data.data.inviteData);
      }, err => {
        this.isLoading = false;
        this.errorMsg = 'Server encountered some problem. Please try again.';
      });
    });

    const result: any[] = await Promise.all([this.promise2, this.promise3])
    this.dashboardData = [];
    for (let data = 0; data < result[0].length; data++) {
      this.dashboardData[data] = {
        ...result[0][data],
        ...result[1][data],
      }
    }
    const dateVal = this.minDate == undefined ?
      new Date(this.maxDate) : new Date(this.minDate);
    for (const key in this.dashboardData) {
      let utcString = dateVal.toDateString().slice(0, dateVal.toDateString().length - 5);
      this.dashboardData[key].utcTime = utcString;
      this.dashboardData[key].dateTime = new Date(dateVal)
      dateVal.setDate(dateVal.getDate() + 1);
    }
    this.prepareTableData(this.dashboardData);
  }

  private prepareTableData(tableData) {
    const dateVal = this.minDate == undefined ?
      new Date(this.maxDate) : new Date(this.minDate);
    for (const key in tableData) {
      this.preparedData[dateVal.getTime()] =
      {
        dateTime: tableData[key].dateTime,
        utcTime: tableData[key].utcTime.slice(0, 3) + "," + tableData[key].utcTime.slice(3, tableData[key].utcTime.length),
        signups: tableData[key].signups,
        // anonymousSignups: tableData[key].anonymousSignups,
        app: tableData[key].alerts.push,
        email: tableData[key].alerts.email,
        sms: tableData[key].alerts.sms,
        returningUsers: tableData[key].ReturningUsers,
        dau: tableData[key].DAU,
        IAP: tableData[key].totalGifts || tableData[key].IAP,
        giftItem: this.aggregateGifts(tableData[key].seriesGifts) || tableData[key].giftItem,
        totalAccepted: tableData[key].totalAccepted,
        totalSent: tableData[key].totalSent,
        influencePointConsumed: tableData[key].influencePoint.consumed,
        influencePointEarned: tableData[key].influencePoint.earned,
        dateSpecificSeriesData: tableData[key].seriesPageViews,
        pageviews: tableData[key].pageviews,
        webPageviews: tableData[key].webPageviews,
        iOSPageviews: tableData[key].iOSPageviews,
        androidPageviews: tableData[key].androidPageviews,
        series: tableData[key].series
      }
      tableData[key].signups && (this.preparedTotals.signups += tableData[key].signups);
      // tableData[key].anonymousSignups && (this.preparedTotals.anonymousSignups += tableData[key].anonymousSignups);
      tableData[key].ReturningUsers && (this.preparedTotals.returningUsers += tableData[key].ReturningUsers);
      tableData[key].alerts.push && (this.preparedTotals.app += tableData[key].alerts.push);
      tableData[key].alerts.email && (this.preparedTotals.email += tableData[key].alerts.email);
      tableData[key].alerts.sms && (this.preparedTotals.sms += tableData[key].alerts.sms);
      tableData[key].DAU && (this.preparedTotals.dau += tableData[key].DAU);
      tableData[key].pageviews && (this.preparedTotals.pageviews += tableData[key].pageviews);
      tableData[key].webPageviews && (this.preparedTotals.webPageviews += tableData[key].webPageviews);
      tableData[key].iOSPageviews && (this.preparedTotals.iOSPageviews += tableData[key].iOSPageviews);
      tableData[key].androidPageviews && (this.preparedTotals.androidPageviews += tableData[key].androidPageviews);
      tableData[key].totalSent && (this.preparedTotals.totalSent += tableData[key].totalSent);
      tableData[key].totalAccepted && (this.preparedTotals.totalAccepted += tableData[key].totalAccepted);
      tableData[key].influencePoint && tableData[key].influencePoint.consumed && (this.preparedTotals.influencePointConsumed += tableData[key].influencePoint.consumed);
      tableData[key].influencePoint && tableData[key].influencePoint.earned && (this.preparedTotals.influencePointEarned += tableData[key].influencePoint.earned);
      tableData[key].IAP && (this.preparedTotals.IAP += tableData[key].IAP);
      dateVal.setDate(dateVal.getDate() + 1);
      this.numberOfDays += 1;
    }
  }

  private aggregateGifts(input: any): { [key: string]: number } {
    const result: { [key: string]: number } = {};

    for (const seriesKey in input.series) {
        const series = input.series[seriesKey];
        for (const giftKey in series.gifts) {
            const gift = series.gifts[giftKey];
            if (!result[gift.reactionName]) {
                result[gift.reactionName] = 0;
            }
            result[gift.reactionName] += gift.count;
        }
    }

    return result;
  }

  openIAPModal(template: TemplateRef<any>, giftItem: any, date, isCheck: boolean) {
    this.IAPModalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-md',
      ignoreBackdropClick: true
    }));
    if (isCheck) {
      this.showDateInGift = this.showDate = date.toDateString().slice(0, 3) + ", " + date.toDateString().slice(4, 10) + ", " + date.toDateString().slice(11, date.length)
    } else {
      this.showDateInGift = date;
    }
    this.giftData = giftItem;
  }

  public async showSeriesTable(data) {
    this.newObj = [];
    this.singleDateTable = null;
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    const myDate = new Date(data.dateTime);
    const formattedDate = formatDate(myDate, format, locale);
    this.showDate = data.dateTime.toDateString().slice(0, 3) + ", " + data.dateTime.toDateString().slice(4, 10) + ", " + data.dateTime.toDateString().slice(11, data.dateTime.length)
    this.showTable = true;
    this.objArray1 = [];
    const pageViewData = data && data.dateSpecificSeriesData ? data.dateSpecificSeriesData.series : null;
    this.hasData = pageViewData ? Object.keys(pageViewData).length != 0 : false;
    if (this.hasData) {
      Object.keys(pageViewData).forEach(key => {
        this.objArray1.push({
          key: key,
          data: pageViewData[key],
        })
      });
    }
    let seriesObj = {};
    for (let i in this.seriesNames) {
      for (let y in this.seriesData) {
        if (this.seriesNames[i] == this.seriesData[y]) {
          seriesObj[i] = this.seriesNames[i]
        }
      }
    }
    for (let data in seriesObj) {
      for (const key in this.objArray1) {
        if (data == this.objArray1[key].key) {
          this.objArray1[key].key = seriesObj[data];
          this.newObj.push(this.objArray1[key]);
          this.singleDateTable += this.objArray1[key].data.pageviews;
        }
      }
    }
    for (let data in seriesObj) {
      for (const key in this.newObj) {
        if (data == this.newObj[key].key) {
          this.newObj[key].key = seriesObj[data];
        }
      }
    }
    this.newObj.sort(function (a, b) {
      if (a.data.pageviews < b.data.pageviews) return 1;
      if (a.data.pageviews > b.data.pageviews) return -1;
      return 0;
    });
    const getDashboardGiftDataSingleDate = await this.apiService.send(API_KEYS.GET_GIFTS_DASHBOARD_DATA_BY_DATE);
    getDashboardGiftDataSingleDate({ date: formattedDate }).then(async (result) => {
      this.giftData = result.data.data.series;
      if (this.newObj.length && Object.keys(this.giftData).length) {
        for (let i of this.newObj) {
          Object.keys(this.giftData).forEach(key => {
            this.getSeries(key);
            if (this.formattedSeries == i.key) {
              i.IAP = this.giftData[key].total,
                i.giftCount = this.giftData[key].giftCount
            }
          })
        }
      }
    }).catch(() => {
      this.hasData = false;
    })
  }

  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return year + "/" + month + "/" + day;
  }

  public setUpdate(update: boolean) {
    this.update = update;
  }

  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  public getSeries(series) {
    this.comicService.getComicTitle().subscribe(res => {
      for (var key in res) {
        if (res[key].title == series) {
          this.formattedSeries = key;
        }
        if (key == series) {
          this.formattedSeries = res[key].title;
        }
      }
    });
  }

  public getGiftTitle(reactionName: string) {
    return (reactionName &&
      reactionName
        .toLowerCase()
        .split("_")
        .map((a) => a[0] && a[0].toUpperCase() + a.slice(1))
        .join(" ")
        .trim())
      || reactionName;
  }
}