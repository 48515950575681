import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ComicService } from 'src/app/services/comic.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FriendService } from 'src/app/services/friend.service';
import { NewComicService } from 'src/app/new-comic.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isAnonymousUser } from 'src/app/utilities/common.util';



@Component({
  selector: 'app-friend-request',
  templateUrl: './friend-request.component.html',
  styleUrls: ['./friend-request.component.scss', './../see-friends/see-friends.component.scss']
})
export class FriendRequestComponent implements OnInit {
  @ViewChild('inviteFriendsModal', { static: false }) inviteFriendsModal;
  isInviteCard = true;
  activeTab = 'received';
  private userPhoneNumber: string;
  isSubscribed: boolean;
  friendList = [];
  isLoading: boolean;
  totalRequestsReceived: number;
  totalRequestsSent: number;
  isAnonymousUser: any;
  influenceCards = [];
  whyInviteImage = `${environment.API_END_POINT}/tinyview/app/why-invite-friends.jpg`;
  inviteSubTitle = {
    nonSubscriber: '',
    subscriber: '',
  }
  isMobile: boolean;
  inviteFriendsRef: any;
  receiverPhoneNumber: any;
  userHasComicAccess: boolean;

  constructor(
    private comicService: ComicService,
    private router: Router,
    private newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private friendService: FriendService,
    private modalService: BsModalService,
    private location: Location,
  ) {
    this.checkSubscriber();
    this.preparePage();
  }

  ngOnInit() {
    this.subscriptionForActiveTab();
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymousUser = isAnonymousUser();
  }

  private async preparePage() {
    await this.checkUserComicAccess();
    if (!this.isAnonymousUser) {
      this.getFriendRequests(true);
      this.userPhoneNumber = this.localStorageService.getItem('userData') && this.localStorageService.getItem('userData').data && this.localStorageService.getItem('userData').data[0].phoneNumber;
    } else {
      // Handle for anonymous User
      this.influenceCards.push({
        title: 'Sign In',
        subTitle: 'Once you sign in, you can access your account from anywhere - app or mobile/desktop browser.',
        image: '../../assets/images/new-icons/icons8-sign-in-100.png',
        btnText: 'Sign In',
        show: this.isAnonymousUser,
        action: 'signIn'
      });
    };
    this.influenceCards.push({
      title: 'Invite Friends',
      subTitle: this.isSubscribed ? this.inviteSubTitle.subscriber : this.inviteSubTitle.nonSubscriber,
      image: '../../assets/images/new-icons/icons8-add-user-group-man-man-100.png',
      btnText: 'Invite Friends',
      disabled: false,
      show: false,
      action: 'inviteFriends'
    })
  }

  private checkSubscriber() {
    // CHECKING IF USER IS SUBSCRIBED OR NOT
    let prodID = this.localStorageService.getItem('productID');
    // let isSubscribedUser = false;
    if (prodID) {
      const prodIDs: Array<string> = prodID;
      prodIDs.forEach(pid => {
        if (!pid.toLowerCase().includes('gifts')) this.isSubscribed = true;
      });
    }
  }

  private subscriptionForActiveTab() {
    this.newComicService.getFriendRequestSelectedTab().subscribe((val) => {
      if (val === 'received') {
        this.localStorageService.setItem('selectedFriendRequestTab', 'received');
        this.activeTab = 'received';
        this.getFriendRequests();
      } else if (val === 'sent') {
        this.localStorageService.setItem('selectedFriendRequestTab', 'sent');
        this.activeTab = 'sent';
        this.getFriendRequests();
      }
    }, err => {
      this.activeTab = 'received';
      this.getFriendRequests();
    })
  }

  private async getFriendRequests(isFromConstructor?: boolean) {
    this.isLoading = true;
    if (isFromConstructor) {
      const sentRequests = await this.friendService.getFriendRequestsByType({
        requestType: 'sent',
        phoneNumber: this.userPhoneNumber,
        status: 'pending'
      });
      this.totalRequestsSent = sentRequests.length;
    }
    const data = await this.friendService.getFriendRequestsByType({
      requestType: this.activeTab,
      phoneNumber: this.userPhoneNumber,
      status: 'pending'
    })
    this.mapData(data);
  }

  private mapData(data) {
    const mapedData = [];
    if (this.activeTab === 'received') {
      data.map(item => {
        const x = {
          userProfilePic: '',
          name: '',
          requestID: ''
        }
        x.userProfilePic = item.senderProfilePic;
        x.name = item.senderName;
        x.requestID = item.id;
        mapedData.push(x);
      });
      this.totalRequestsReceived = mapedData.length;
    } else if (this.activeTab === 'sent') {
      data.map(item => {
        const x = {
          userProfilePic: '',
          name: '',
          requestID: '',
          receiverPhoneNumber: ''
        }
        x.userProfilePic = item.receiverProfilePic;
        x.receiverPhoneNumber = item.receiverPhoneNumber;
        x.name = item.receiverName;
        x.requestID = item.id;
        mapedData.push(x);
      });
      this.totalRequestsSent = mapedData.length;
    }
    this.friendList = mapedData;
    this.isLoading = false;
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str)
    return firstLetters;
  }

  public navigateTo(type: string): void {
    switch (type) {
      case 'received':
        // this.activeTab = 'received';
        this.newComicService.setFriendRequestSelectedTab('received');
        // this.router.navigate(['friend-request']); // This is causing a bug when clicked on another tab, when we have optional query param
        break;
      case 'sent':
        // this.activeTab = 'sent';
        this.newComicService.setFriendRequestSelectedTab('sent');
        // this.router.navigate(['friend-request']);
        break;
      default:
        break;
    };
  }

  public getButton(activeTab: string) {
    if (activeTab === 'received') {
      return 'Accept';
    } else if (activeTab === 'sent') {
      return 'Resend';
    }
  }

  public closeInviteFriendCard() {
    this.isInviteCard = false;
  }

  private setRequestStatus(requestStatus: string) {
    switch (requestStatus) {
      case 'accepted':
        if (this.activeTab === 'sent') {
          return 'resend';
        } else {
          return requestStatus;
        }
        break;
      case 'delete':
        if (this.activeTab === 'received') {
          return 'rejected';
        } else {
          return requestStatus;
        }
        break;
    }
  }

  public async actionOnRequest(event: any, requestStatus: string, request: any) {
    event.target.classList.add('disabled');
    const status = this.setRequestStatus(requestStatus);
    if (status !== 'resend') {
      const data = {
        status: status,
        requestID: request.requestID
      }
      const res = await this.friendService.updateFriendRequestByReqID(data);
      if (res.status === '200') {
        event.target.classList.remove('disabled');
        this.getFriendRequests();
      } else {
        event.target.classList.remove('disabled');
        this.getFriendRequests();
      }
    } else {
      this.receiverPhoneNumber = request.receiverPhoneNumber;
      this.openInviteFriendsModal(this.inviteFriendsModal);
      event.target.classList.remove('disabled');
    }
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymousUser ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  async checkUserComicAccess() {
    const accessAllowed = await this.comicService.checkUserComicAccess();
    if (accessAllowed.hasAllComicAccess) {
      this.userHasComicAccess = true;
    } else {
      this.userHasComicAccess = false;
    }
    this.inviteSubTitle = {
      nonSubscriber: `Know when a friend likes or comments on a comic. Invite friends and earn 5 influence points for every invite accepted!`,
      subscriber: 'Know when a friend likes or comments on a comic.',
    }
  }
}
