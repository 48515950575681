import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule, MatButtonModule, MatChipsModule, MatDialogModule, MatIconModule, MatListModule, MatRadioButton, MatRadioModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
@NgModule({
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
  ]
})
export class AppMaterialModule { }
