<div class="container">
  <div class="row">
    <div class="col-6">
      <img src="../../assets/images/comic-header.gif"/>
    </div>
    <div class="col-6">
      <img src="../../assets/images/content-placeholder.gif"/>
    </div>
  </div>
</div>
