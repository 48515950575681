import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { isPlatformBrowser } from '@angular/common';
import { ComicService } from './comic.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  currentDevice: string;

  constructor(
    public authService: AuthService,
    public router: Router,
    public comicService: ComicService,
    private localStorageService: LocalStorageService,
    public afAuth: AngularFireAuth,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.currentDevice = this.comicService.getOperatingSystem();
    if (isPlatformBrowser(this.platform)) {
      this.localStorageService.setItem('isdashboardOpen', JSON.stringify(true));
    }
    const isSignedInUser = this.authService.isSignedInUser();
    if (!isSignedInUser) {
      this.router.navigate(['signin/phone']);
      return false;
    } else {
      return true;
    }
  }
}
