import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { StoryFeedService } from './story-feed.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class StoryPageGuard implements CanActivate {
  currentDevice: string;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: object,
    private feedService: StoryFeedService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platform)) {
      let pathParam = next.paramMap.get('ID');
      if (pathParam === 'action') {
        const actionURL = next.queryParamMap.get('actionURL');
        let getStory = this.feedService.getStory(actionURL);
        getStory.then(async resp => {
          const storyID = resp.data.data.storyID;
          this.router.navigate([`story/${storyID}`]);
        })
        // return true;
      } else {
        return true;
      }
  }
  }
}
