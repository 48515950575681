<div class="main-container">
	<div class="section-container" [ngClass]="{'tv-card mb-100': !isMobile, 'pb-100': isMobile}">
		<ng-container *ngIf="!isUserDetailsLoading">
			<h1>Manage Alerts</h1>
	
			<form [formGroup]="alertsForm">
				<ng-container *ngFor="let alert of alertsConfig">
					<div *ngIf="alert.show" formGroupName="{{ alert.id }}">
						<div class="alert-type" [ngClass]="{ 'mb-1': !alert.subKey, 'first-alert' : alert.id === 'push'}">
							<div class="alert-key">
								<h2>{{alert.key}}</h2>
								<div *ngIf="alert.disable" class="body-mini mt-2"><a (click)="onOpenAppClick(QRModal)" class="tv-link">Install App</a>&nbsp; for push notifications.</div>
								<div *ngIf="alert.id === 'push' && !alert.disable" class="body-mini mt-2">Remember to enable Push Notifications <br> on your mobile device.</div>
								<div class="body-mini alert-subKey" *ngIf="alert.subKey">
									{{alert.subKey}}
									<img *ngIf="alert.verified" src="../../assets/images/new-icons/icons8-checkmark-100 green.png"
										class="icon-20 ml-2">
								</div>
							</div>
							<div *ngIf="!alert.disable">
								<ng-toggle
								[disabled]="alert.disable"
								[value]="alertsForm.value[alert.id][alert.id]"
								[width]="switchConfig.width"
								[height]="switchConfig.height"
								[margin]="switchConfig.margin"
								[color]="switchConfig.color"
								[switchColor]="switchConfig.switchColor"
								[formControlName]="alert.id"
								(change)="toggleAlert(alert.id, true)"
							></ng-toggle>
							</div>
						</div>
						<div formGroupName="subAlerts" class="mt-2" *ngIf="alertsForm.value[alert.id][alert.id] === true">
							<ng-container *ngFor="let subAlert of alert.subAlerts">
								<div class="sub-alert" *ngIf="subAlert.show && subAlert.key">
									<p class="col-9 p-0">{{subAlert.key}}</p>
									<ng-toggle class="mt-1"
									[value]="alertsForm.value[alert.id].subAlerts[subAlert.id]"
									[width]="switchConfig.width"
									[height]="switchConfig.height"
									[margin]="switchConfig.margin"
									[color]="switchConfig.color"
									[switchColor]="switchConfig.switchColor"
									[formControlName]="subAlert.id"
								></ng-toggle>
								</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</form>
		</ng-container>
	
		<div class="dot-loader" *ngIf="isUserDetailsLoading">
			<img src="../../assets/images/new-icons/icons8-loading-v2.gif" alt="loader">
		</div>
	</div>
</div>

<ng-template #QRModal>
  <div class="action-container">
		<div>
			<button type="button" class="ml-auto d-flex" aria-label="Close" (click)="modalRef.hide()">
				<img src="../../assets/images/new-icons/icons8-delete-100.png" class="cross-btn"/>
		 </button>
		</div>
		<div class="mx-4 px-1 my-4 text-center">
			<img src="../../assets/images/badge-tinyview-100.png" width="32px" height="32px" alt="">
			<div class="my-3 mx-auto qr-container">
				<img width="164px" width="164px" src="../../assets/images/new-icons/install-app-QR-code.jpeg" alt="scan-qr">
			</div>
			<p>Scan to open the app</p>
		</div>
  </div>
</ng-template>