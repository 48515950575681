import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MONTHS_ARRAY } from "src/app/constants/common.constants";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: 'app-series-filter',
  templateUrl: './series-filter.component.html',
  styleUrls: ['./series-filter.component.scss', '../dashboard-stats/dashboard-stats.component.scss']
})

export class SeriesFilterComponent implements OnInit {
  @Output() sendFilterData: EventEmitter<any> = new EventEmitter<any>();
  @Input() isLoading: boolean;
  @Input() isReferralPage?: boolean;
  form: FormGroup;
  months = MONTHS_ARRAY;
  years = [];
  seriesToShow = []; 
  currentDate = new Date();
  selectedYear: any;
  selectedMonth: any;
  currentMonth: { name: string; number: number; }[];
  public userEvent: boolean;

  constructor(
    private dashboardService: DashboardService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.setFilters();

    this.dashboardService.loadPreviousMonthData().subscribe((data) => {
      if (data.loadPreviousMonth) {
        // handling when there is not data in january, so will set december of previous year
        if (this.form.value.month === 1) {
          this.form.get('year').setValue(this.form.value.year - 1);
          this.form.get('month').setValue(12);
        } else {
          this.form.get('month').setValue(this.form.value.month - 1);
        }
        
        setTimeout(() => {
          this.userEvent = false;
          this.prepareDataAndEmit();
        }, 200);
      }
    });
  }
  
  setFilters() {
    this.generateYearOptions();
    const currentYear = this.currentDate.getFullYear();
    const currentMonth = this.currentDate.getMonth() + 1;
    this.selectedYear = currentYear;
    this.selectedMonth = currentMonth;
    this.form = this.fb.group({
      month: [currentMonth, Validators.required],
      year: [currentYear, Validators.required],
      series: ['', Validators.required],
    });
    this.dashboardService.getDirectoryData().subscribe((res) => {
      this.seriesToShow = res.seriesToShow;
      this.form.controls.series.setValue(this.seriesToShow && this.seriesToShow[0].value);
    })
  }

  onYearChange(event) {
    this.selectedYear = event.target.value;
    // Reset the month selection if the year changes
    this.form.get('month').reset();
  }

  onMonthChange(event) {
    this.userEvent = true;
  }

  getAvailableMonths(): { name: string, number: number }[] {
    const currentMonth = this.currentDate.getMonth() + 1;
    if (parseInt(this.selectedYear) === this.currentDate.getFullYear()) {
      if (!this.form.get('month').value) this.form.get('month').setValue(this.months.slice(0, currentMonth)[0].number);
      return this.months.slice(0, currentMonth); // Only show months up to current month for the current year
    } else if (parseInt(this.selectedYear) === 2023) {
      if (!this.form.get('month').value) this.form.get('month').setValue(this.months.slice(11, 12)[0].number);
      return this.months.slice(11, 12); // Only show December for year 2023
    } else {
      if (!this.form.get('month').value) this.form.get('month').setValue(this.months[0].number);
      return this.months;
    }
  }

  generateYearOptions() {
    const currentYear = this.currentDate.getFullYear();
    const endYear = 2023;
    const years = [];
  
    for (let year = currentYear; year >= endYear; year--) {
      years.push(year);
    }
  
    this.years = years;
  }

  prepareDataAndEmit() {
    const fromDate = `${this.form.controls.year.value}-${this.form.controls.month.value.toString().padStart(2, '0')}-01`;
    const seriesValue = this.form.get('series').value;
    const sampleObject = { from: fromDate, series: seriesValue, userEvent: this.userEvent };
    this.sendFilterData.emit(sampleObject);
  }
}