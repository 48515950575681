import {
  AngularFireStorage, AngularFireUploadTask,
} from '@angular/fire/storage';
import { Inject, Injectable } from '@angular/core';
import { Comic } from '../../models/comic';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public imageRefNames:any =[];
  percentage: Observable<number>;
  snapshot: Observable<unknown>;
  downloadURL: any;
  storeComics$: Subject<any> = new Subject<any>();
  constructor(private readonly storage: AngularFireStorage) {
  }

  public getFilePath(comicSeries: string,
    comicDate: Date,
    comicTitle: string) {
    var series = this.toFileFormat(comicSeries);
    series = series && series.startsWith('/') ? series : '/' + series;
    if (comicTitle == null) {
      return `${series}`;
    }

    const date = this.formatDate(comicDate);
    const title = this.toFileFormat(comicTitle);
    return `${date}/`;
  }

  public  async uploadPanelImageAndGetTask({ path, file, timestamp }: { path: string; file: File; timestamp: number; }) {
      if (file) {
        const uploadTask: AngularFireUploadTask =  this.storage.upload(`${path}/${file.name}`, file)
        return uploadTask;
    }
  }

  public async deletePanelImageAndGetTask(path: string, name: any) {
    await  this.storage.storage.ref(`${path}/${name}`).delete().catch((error) => {
      throw error;
    });
  }

  public uploadIndex(path: string, comic: Comic): void {
    this.storeComics$.next(false);
    const blob = new Blob([JSON.stringify({ comics: comic }, null, 2)], { type: 'application/json' });
    this.storage.upload(`${path}/index.json`, blob).then((res)=>{
      if (res.state == "success") {
        this.storeComics$.next(true);
      }
    }).catch((error) => {
      console.log(error);
      this.storeComics$.next(false);
        throw error;
      });
  }

  public formatDate(date: Date): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('/');
  }

  public toFileFormat(series: String): string {
    return series.replace(/\s/g, '-').toLowerCase();
  }
}
