
export const planChangeMsg = {
  Subscribe: 'Are you sure you want to subscribe?',
  Upgrade: 'Are you sure you want to upgrade from {{current}} to {{requested}}?',
  Downgrade: 'Are you sure you want to change the subscription from {{current}} to {{requested}}?',
  Cancel: 'If you cancel your subscription now, you can still access it until {{variable}}.'
};

export const planChangeHeader = {
  Subscribe: 'Please Confirm',
  Upgrade: 'Please Confirm',
  Downgrade: 'Please Confirm',
  Cancel: 'Please Confirm'
};

export const planChangeCtaText = {
  Subscribe: 'Subscribe',
  Upgrade: 'Upgrade',
  Downgrade: 'Yes',
  Cancel: 'Confirm'
};

export const cancelCtaText = {
  Subscribe: 'Cancel',
  Upgrade: 'Cancel',
  Downgrade: 'Cancel',
  Cancel: 'Not Now'
};

export const subscriptionLevels = ['cookie', 'bagel', 'coffee', 'artsupplies', 'pizza'];
// export const gifts = ['COOKIE', 'BAGEL', 'COFFEE', 'ART_SUPPLIES', 'PIZZA']; // loading it dynamically from getGiftItems API
export const subscriptionLevelsFull = [
  'com.tinyview.subscription',
  'com.tinyview.subscription.bagel',
  'com.tinyview.subscription.coffee',
  'com.tinyview.subscription.artsupplies',
  'com.tinyview.subscription.pizza'
];

export const productIDs = {
  cookie: 'com.tinyview.subscription',
  bagel: 'com.tinyview.subscription.bagel',
  coffee: 'com.tinyview.subscription.coffee',
  artsupplies: 'com.tinyview.subscription.artsupplies',
  pizza: 'com.tinyview.subscription.pizza'
};

export const subscriptionCreatorArray = [
  {
    key: "Cookie Level Subscribers",
    value: productIDs.cookie
  },
  {
    key: "Bagel Level Subscribers",
    value: productIDs.bagel,
  },
  {
    key: "Coffee Level Subscribers",
    value: productIDs.coffee,
  },
  {
    key: "Art Supplies Level Subscribers",
    value: productIDs.artsupplies,
  },
  {
    key: "Pizza Level Subscribers",
    value: productIDs.pizza,
  },
];

export const subscriptionPrice = {
  cookie: '$0.99 per month',
  bagel: '$2.49 per month',
  coffee: '$4.99 per month',
  artsupplies: '$9.99 per month',
  pizza: '$19.99 per month'
};

export const planChangeMsgHelper = {
  cookie: 'Cookie Level ($0.99 per month)',
  bagel: 'Bagel Level ($2.49 per month)',
  coffee: 'Coffee Level ($4.99 per month)',
  artsupplies: 'Art Supplies Level ($9.99 per month)',
  pizza: 'Pizza Level ($19.99 per month)'
};

export const stores = {
  APPLE: 'Apple',
  GOOGLE: 'Google',
  STRIPE: 'Stripe'
}

export const subscriptionAnonymousMsg = `Subscription successful! Now Sign In so you don't lose your subscription details.`;

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];