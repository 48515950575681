<div class="full-height-mobile">
  <div class="tv-card">
    <h1>Sign In Successful</h1>

    <img class="mt-3" [src]="getImageURL('/tinyview/app/why-invite-friends.jpg')">
  
    <!-- Button to verify the code -->
    <div class="d-flex">
        <button class="btn tv-btn-light mr-2 send-btn success" (click)="successfulLogin()">
            <span class="p-bold">Do It Later</span>
        </button>
        <button class="btn tv-btn-red ml-2 send-btn success"
            (click)="openInviteFriendsModal(inviteFriendsModal)">
            <span class="p-bold">Invite Friends</span>
        </button>
    </div>
  </div>
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>