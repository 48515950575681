import { Component } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { phoneVerificationURL, signInPhoneURL } from '../constants/common.constants';



@Component({
  selector: 'app-channel-verification',
  templateUrl: './channel-verification.component.html',
  styleUrls: ['./channel-verification.component.scss']
})
export class ChannelVerificationComponent {
  constructor(
    private readonly comicService: ComicService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.urlHandling(this.comicService.getCurrentUrl());
  }

  urlHandling(url) {
    let queryParams = null;
    if (this.route.snapshot.queryParams) {
      queryParams = this.route.snapshot.queryParams;
    }
    if (url === '/signin') this.router.navigate([signInPhoneURL], { queryParams });
    // if (url === '/verification') this.router.navigate([phoneVerificationURL], { queryParams});
  }
 }
