import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DailyData, DailyDataCalculation } from "src/app/adapters/daily-creator-data";
import { CREATOR_DASHBOARD_COLUMNS } from "src/app/constants/common.constants";
import { DashboardService } from "src/app/services/dashboard.service";
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from "src/app/services/user.service";
import { creatorViewTable } from "src/app/utilities/common.util";

interface Filters {
  series: string;
  startDate: string;
  userEvent: boolean;
};

interface DateRange { startDate: number, endDate: number };

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})

export class DashboardStatsComponent implements OnInit {
  tinyViewAggregates;
  userName: string;
  public dateRange: DateRange;
  currentDate = new Date();

  public calculatedDailyData = [];
  public calculatedFor: string;
  public totalData = {};

  public revenuePerPage: number;
  monthlyAggregate: DailyDataCalculation;
  seriesRevenue: any;
  error: string;
  tableError: string;
  isLoading: boolean;

  public tableHead = CREATOR_DASHBOARD_COLUMNS;
  monthlyAggregateView: any;
  calculatedDailyDataView = [];

  constructor(
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private router: Router,
    private readonly userService: UserService
  ) {
    this.getUserDetail();
  }

  ngOnInit(): void {
    this.setCreatorsDashboardPage();
  }

  private async getUserDetail() {
    const username = await this.userService.getUserDetails();

    this.userName = username && username.data && username.data.data.displayName;
  }

  public setCreatorsDashboardPage() {
    this.error = '';
    this.isLoading = true;
    this.dashboardService.getDirectoryData().subscribe((res) => {
      if (!res.seriesToShow) {
        this.error = 'You are not authorized to visit this page!';
        this.router.navigate(['']);
        return;
      }
      const fromDate = `${this.currentDate.getFullYear()}-${(this.currentDate.getMonth() + 1).toString().padStart(2, '0')}-01`;
      const seriesValue: string = res.seriesToShow[0].value;
      const filter: Filters = { startDate: fromDate, series: seriesValue, userEvent: false };
      this.loadDailyData(filter);
    }, () => {
      this.isLoading = false;
      this.error = 'Something went wrong, please refresh the page!';
    })
  }

  public onDataReceived(data: any) {
    const filters: Filters = {
      startDate: data.from,
      series: data.series,
      userEvent: data.userEvent
    };
    this.loadDailyData(filters);
  }

  public async loadDailyData(filters: Filters) {
    this.calculatedDailyData = [];
    this.error = '';
    this.tableError = '';
    this.isLoading = true;
    this.dashboardService.getCreatorDashboardData(filters).then(res => {
      const dailyData = <DailyData>res.data.dayWiseRevenue;
      const monthlyData = res.data.monthlyRevenue;

      const noDataCondition = (monthlyData && Object.keys(monthlyData) && !Object.keys(monthlyData).length) || (dailyData && Object.keys(dailyData) && !Object.keys(dailyData).length) || !dailyData || !monthlyData;
      if (!filters.userEvent && noDataCondition) {
        this.emitLoadPreviousMonthData();
        return;
      } else if (noDataCondition) {
        this.tableError = 'No data available yet.';
        this.isLoading = false;
        return;
      }

      this.revenuePerPage = monthlyData.totalSubscriptionAmount / monthlyData.pageviews;

      let latestDate = 0;

      const monthlyTotal = {
        "monthly": 'Total',
        'date': `${new Date().getTime()}`,
        "webPageviews": 0,
        "iOSPageviews": 0,
        "androidPageviews": 0,
        "unknownPageviews": 0,
        "pageviews": 0,
        "seriesPageViews": {
          "lastUpdated": 0,
          "series": {}
        },
        "totalGifts": 0,
        "totalGiftsAmount": 0,
        "seriesGifts": {
          "lastUpdated": 0,
          "series": {}
        },
        "lastUpdated": 0
      };
      for (const [timestamp, data] of Object.entries(dailyData)) {
        data['date'] = timestamp;
        const singleDateData = new DailyDataCalculation(data, filters.series, { monthlyPageViews: monthlyData.pageviews, revenuePerPage: this.revenuePerPage });
        this.calculatedDailyData.push(singleDateData);
        monthlyTotal.pageviews += data.pageviews;

        if (monthlyTotal.seriesPageViews.series[`${filters.series}`]) {
          monthlyTotal.seriesPageViews.series[`${filters.series}`]['webPageviews'] += data.seriesPageViews.series[`${filters.series}`]['webPageviews'];
          monthlyTotal.seriesPageViews.series[`${filters.series}`]['iOSPageviews'] += data.seriesPageViews.series[`${filters.series}`]['iOSPageviews'];
          monthlyTotal.seriesPageViews.series[`${filters.series}`]['androidPageviews'] += data.seriesPageViews.series[`${filters.series}`]['androidPageviews'];
          monthlyTotal.seriesPageViews.series[`${filters.series}`]['pageviews'] += data.seriesPageViews.series[`${filters.series}`]['pageviews'];
        } else {
          monthlyTotal.seriesPageViews.series[`${filters.series}`] = data.seriesPageViews.series[`${filters.series}`];
        }

        if (monthlyTotal.seriesGifts.series[`${filters.series}`]) {
          monthlyTotal.seriesGifts.series[`${filters.series}`]['totalGifts'] += data.seriesGifts.series[`${filters.series}`]['totalGifts'];
          monthlyTotal.seriesGifts.series[`${filters.series}`]['totalGiftsAmount'] += data.seriesGifts.series[`${filters.series}`]['totalGiftsAmount'];


          const gifts = data.seriesGifts.series[`${filters.series}`]['gifts'];

          for (const key in gifts) {
            let monthlyGiftObj = monthlyTotal.seriesGifts.series[`${filters.series}`]['gifts'];
            if (gifts.hasOwnProperty(key)) {
              const existingGift = monthlyGiftObj[key];

              if (existingGift) {
                // If the gift already exists, sum quantity and seriesRevenue
                existingGift.amount += data.seriesGifts.series[`${filters.series}`]['gifts'][key]['amount'];
                existingGift.count += data.seriesGifts.series[`${filters.series}`]['gifts'][key]['count'];
                existingGift.reactionName = data.seriesGifts.series[`${filters.series}`]['gifts'][key]['reactionName'];
              } else {
                // If the gift doesn't exist, create a new entry
                const newMonthlyGiftObj = {
                  amount: data.seriesGifts.series[`${filters.series}`]['gifts'][key]['amount'],
                  count: data.seriesGifts.series[`${filters.series}`]['gifts'][key]['count'],
                  reactionName: data.seriesGifts.series[`${filters.series}`]['gifts'][key]['reactionName']
                };

                monthlyGiftObj[key] = newMonthlyGiftObj;
              }
            }
            monthlyTotal.seriesGifts.series[`${filters.series}`]['gifts'] = monthlyGiftObj;
          }

        } else {
          monthlyTotal.seriesGifts.series[`${filters.series}`] = data.seriesGifts.series[`${filters.series}`];
        }

        // keeping last date
        if (latestDate < parseInt(timestamp)) latestDate = parseInt(timestamp);
      }
      const formattedMonthAggregate = new DailyDataCalculation(monthlyTotal, filters.series, { monthlyPageViews: monthlyData.pageviews, revenuePerPage: this.revenuePerPage });
      formattedMonthAggregate.virtualGifts.date = this.dashboardService.getMessageForDate(latestDate);
      this.calculatedFor = formattedMonthAggregate.virtualGifts.date.displayValue;

      this.monthlyAggregate = formattedMonthAggregate;
      this.seriesRevenue = formattedMonthAggregate.seriesRevenue;

      this.calculatedDailyData = this.dashboardService.sortArrayByDate(this.calculatedDailyData);

      this.tinyViewAggregates = {
        tinyViewRevenue: `$${DailyDataCalculation.formatNumberToUSFormat(monthlyData.totalSubscriptionAmount)}`,
        tinyViewPageViews: DailyDataCalculation.formatNumberToUSFormat(monthlyData.pageviews, false),
        seriesRevenue: this.seriesRevenue.displayValue // already formatted
      }
      this.isLoading = false;
      this.setViewData();
    }, () => {
      this.isLoading = false;
      this.error = 'Unable to load dashboard data, please refresh the page!';
    });
  }

  public emitLoadPreviousMonthData(): void {
    this.dashboardService.emitLoadPreviousMonthData({loadPreviousMonth: true});
  }

  private setViewData() {
    this.calculatedDailyDataView = [];
    this.monthlyAggregateView = {};
    this.calculatedDailyData.forEach(data => {
      const mapped = creatorViewTable(data);
      this.calculatedDailyDataView.push(mapped);
    });
    this.monthlyAggregateView = creatorViewTable(this.monthlyAggregate);
  }
}
