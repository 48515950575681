import * as firebase from "firebase";

export function initializeApp() {
	return () => {
		// All initialization code here, it can be asynchronous
		return checkFirebaseUser();
	};
}

// This function will take care of checking a user and creating one if not present
function checkFirebaseUser() {
	return new Promise<void>((resolve) => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (!user) {
					firebase
						.auth()
						.signInAnonymously()
						.then(() => {
							resolve();
						})
						.catch((error) => {
							console.log('Error while creating new user.');
							console.log(error);
							resolve();
						});
				} else {
					resolve();
				}
			},
			(error) => {
				console.log('Error in onAuthStateChanged checking.');
				console.log(error);
				resolve();
			}
		);
	});
}
