import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PostActions from '../redux/action';
import { Post } from '../redux/model';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ComicService } from '../services/comic.service';

interface AppState {
  post: Post;
}

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss', '../comic/comic.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  currentDevice: string;
  isIpad: boolean;

  constructor(
    private store: Store<AppState>,
    private comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private meta: Meta) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (width == 1024) {
        this.isIpad = true;
      }
    }
    this.currentDevice = this.comicService.getOperatingSystem();
    // const metaData = [
    //   { property: 'twitter:description', content: 'Privacy Policy'},
    //   { property: 'twitter:image',
    //   content: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg' },
    //   { property: 'twitter:title', content: 'Privacy Policy' },
    //   { property: 'og:title', content: 'Privacy Policy' },
    //   { property: 'og:description', content: 'Privacy Policy' },
    //   { property: 'og:image',
    //   content: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg' },
    //   { property: 'og:url', content: 'https://tinyview.com/privacy-policy' },
    // ];
    // for (const metaProperty of metaData) {
    //   this.meta.updateTag({ property: metaProperty.property, content: metaProperty.content });
    // }
    // this.store.dispatch(new PostActions.EditText('Privacy Policy'));
  }

}
