import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.scss']
})
export class DataNotFoundComponent implements OnInit {
  urlSubscribe: Subscription;
  constructor(public router: Router,@Inject(PLATFORM_ID) private platform: object) { }

  ngOnInit() {
    this.urlSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (isPlatformBrowser(this.platform)) {
          // window.location.reload(); // it was causing the redirection back to wrong url
        }
      } else if (event instanceof NavigationEnd) {
      }
    });
			
   }

  onNavigate() {
    this.router.navigate(['']).then(()=>{
      // location.reload(); // why it was required?
    })
  }
  ngOnDestroy() {
    if (this.urlSubscribe) {
      this.urlSubscribe.unsubscribe();
    }
  }
}
