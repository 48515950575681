<ng-container [formGroup]="userDobForm">
  <h2 class="mt-1">Date of Birth</h2>
  <p class="body-mini mt-2">You must be at least 13 years to proceed.</p>
  <div>
    <ng-container>
      <label for="birthYear">
          <p class="body-mini mt-3">Year<span class="mb-1" >*</span></p>
          <input id="birthYear" type="number" (keyup)="validateAge($event)" (blur)="validateAge($event)" formControlName="birthYear" placeholder="YYYY" appNumericOnly>
          <div class="body-mini error-text mt-2" *ngIf="getBirthYearErrorMessage()"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px"><span class="copy-message">{{ getBirthYearErrorMessage() }}</span></div>
      </label>
      <label for="birthMonth" *ngIf="showMonthInput || userDobForm.get('birthMonth').value">
          <p class="body-mini mt-3">Month<span class="mb-1" >*</span></p>
          <input id="birthMonth" type="number" (keyup)="validateAge($event)" (blur)="validateAge($event)" formControlName="birthMonth" name="MM"  placeholder="MM" appNumericOnly>
          <div class="body-mini error-text mt-2" *ngIf="getBirthMonthErrorMessage()"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px"><span class="copy-message">{{ getBirthMonthErrorMessage() }}</span></div>
      </label>
      <label for="birthDay" *ngIf="showDayInput || userDobForm.get('birthDay').value">
          <p class="body-mini mt-3">Day<span class="mb-1" >*</span></p>
          <input id="birthDay" type="number" (keyup)="validateAge($event)" (blur)="validateAge($event)" formControlName="birthDay" name="DD"  placeholder="DD" appNumericOnly>
          <div class="body-mini error-text mt-2"  *ngIf="getBirthDayErrorMessage()"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px"><span class="copy-message">{{ getBirthDayErrorMessage() }}</span></div>
      </label>
      <div class="mt-5">
        <div *ngIf="userDobForm.hasError('ageInvalid')" class="body-mini error-text mt-2"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px"><span class="copy-message">Whoops! Looks like you’re not 13 yet – no worries, though! You can still dive into our comic world. Just hit ‘back’ and start reading. Sign-up can wait until you hit the big 1-3!</span></div>
        <button (click)="updateDoB()" [class.disabled]="userDobForm.invalid"
        [class.p-2]="loadingButton"
        [disabled]="userDobForm.invalid"><span class="loader-bar" *ngIf="loadingButton"></span><span *ngIf="!loadingButton" class="p-bold text-inverse">Next</span></button>
        <p class="text-color body-mini text-center mt-2">Already a user? <a class="anchor" [routerLink]="['/signin/phone/number']">Sign In</a></p>
      </div>
    </ng-container>
  </div>
</ng-container>
