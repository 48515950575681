import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  private localData = {};

  constructor() { }

  private isSessionStorageApiExists(): boolean{
    return (typeof sessionStorage !== "undefined");
  }

  private setData(key: string, value: any): void {
    if (value && typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    if (this.isSessionStorageApiExists()) {
      sessionStorage.setItem(key, value);
    } else {
      this.localData[key] = value;
    }
  }

  // Create a new item in local storage
  public setItem(key: string, value: any): void {
    this.setData(key, value);
  }

  // Retrieve an item from local storage
  public getItem(key: string): any {
    let item = null;

    if (this.isSessionStorageApiExists()) {
      item = sessionStorage.getItem(key);
    } else {
      item = this.localData[key];
    }

    try {
      return item ? JSON.parse(item) : null;
    } catch (err) {
      return item;
    }
  }

  // Update an existing item in local storage
  public updateItem(key: string, value: any): void {
    this.setData(key, value);
  }

  // Delete an item from local storage
  public removeItem(key: string): void {
    if (this.isSessionStorageApiExists()) {
      sessionStorage.removeItem(key);
    } else if(this.localData[key]) {
      delete this.localData[key];
    }

  }

  // Delete all items from local storage
  public clear(): void {
    if (this.isSessionStorageApiExists()) {
      sessionStorage.clear();
    } else {
      this.localData = {};
    }
  }
}
