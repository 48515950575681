import { Component, Inject, Input, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { isPlatformBrowser } from '@angular/common';
import { CacheService } from "src/app/services/cache.service";
import { LocalStorageService } from "src/app/services/local-storage.service";


@Component({
  selector: 'app-follow-modal',
  templateUrl: './follow-modal.component.html',
  styleUrls: ['./follow-modal.component.scss']
})
export class FollowModalComponent {
  @Input() hasBonusPanel: boolean;
  @Input() tinyviewPage: boolean;
  @Input() isFollowingAlready: boolean;
  @Input() series: any;
  isSubscriber: boolean;
  constructor(
    public modalRef: BsModalRef,
    private readonly router: Router,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platform: object,
    private cacheService: CacheService
  ) {
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
  }

  closeModal(isFollowingAlreadyModal) {
    if (isFollowingAlreadyModal && isPlatformBrowser(this.platform)) {
      this.cacheService.set('hideFollowingAlreadyModal', true, 86400000);
    }
    this.modalRef.hide();
  }

  onManageAlertClick(): void {
    if (this.isFollowingAlready && isPlatformBrowser(this.platform)) {
      this.cacheService.set('hideFollowingAlreadyModal', true, 86400000);
    }
    if (this.modalRef) this.modalRef.hide();
    const queryParams = {}
    queryParams['series'] =  JSON.stringify(this.series);
    if (this.hasBonusPanel) queryParams['hasBonusPanel'] = this.hasBonusPanel;
    this.router.navigate(['manage-alerts'], { queryParams });
  }
 }