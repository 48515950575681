<div class="profile-container" [formGroup]="profileForm">
  <h2 *ngIf="!editProfileFlow" class="mt-1">Create Profile</h2>
  <h1 *ngIf="editProfileFlow">Edit Profile</h1>
  <div class="file-upload d-flex flex-wrap">
    <!--place upload image/icon first !-->
    <a (click)="openProfilePicOptionModal(profilePicOptionModal)" class="mx-auto">
      <img class="user-placeholder mx-auto" src="../../../assets/images/new-icons/icons8-male-user-100.png"
      *ngIf="!userImage" />
    <img class="user-placeholder mx-auto image-border" [src]="userImage" *ngIf="userImage" />
    </a>
    <span class="mx-auto body-mini profile-text" [class.choose-profile-text]="!userImage">
      <a (click)="openProfilePicOptionModal(profilePicOptionModal)">
        <span *ngIf="!userImage">Choose</span><span *ngIf="userImage">&nbsp;&nbsp;Edit</span> Profile Picture
      </a>
    </span>
  </div>
  <label for="name">
    <p class="body-mini mt-4 pt-1">Name<span class="mb-1">*</span></p>
    <input formControlName="name" (blur)="validateField('name')" matInput placeholder="E.g. Chris Matt" [ngClass]="{ 'filled-and-valid': isFilledAndValid('name'), 'invalid': isInvalid('name') }">
    <div *ngIf="profileForm.get('name').hasError('required') && profileForm.get('name').touched"
      class="error-text mt-2"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px"
        width="16px"><span class="copy-message">Required field.</span>
    </div>
    <div
      *ngIf="!profileForm.get('name').hasError('required') && profileForm.get('name').hasError('invalidName') && profileForm.get('name').touched"
      class="error-text mt-2"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px"
        width="16px"><span class="copy-message">Please enter alphanumeric starting with a letter.</span>
    </div>
  </label>
  <label for="pronounsDropdown" class="body-mini mt-4 pt-1">Pronouns<span class="mb-1">*</span></label>
  <div class="pronoun-select">
    <p class="selected-option d-flex justify-content-between" (click)="toggleDropdown()" [ngClass]="{ 'filled-and-valid': selectedOption.label !== 'Select option', 'not-selected-option': selectedOption.label === 'Select option' }"><span>{{selectedOption.label}}</span> <img
        class="chevron my-auto" [src]="isDropdownOpen ? arrowUp : arrowDown"></p>
    <ul *ngIf="isDropdownOpen" class="shadow bg-white rounded dropdown-options mt-1">
      <p *ngFor="let option of pronoun" class="text-bold-color d-flex justify-content-between" (click)="selectOption(option)">
        <span>{{ option.label }}</span> <img *ngIf="selectedOption.label === option.label" class="chevron my-auto" src="../../../assets/images/new-icons/icons8-checkmark-100.png"></p>
    </ul>
  </div>
  <div *ngIf="genderError" class="error-text mt-2"><img
      src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px"><span class="copy-message">Required
      field.</span>
  </div>

  <label for="link" class="body-mini mt-4 pt-1 d-flex justify-content-between">
    <span>Link</span> 
    <img src="../../assets/images/new-icons/icons8-delete-100.png" *ngIf="profileForm.get('link').value.length !== 0" (click)="clearLink()" class="cross-icon" alt="X">
  </label>
  <input formControlName="link" matInput placeholder="Add Link" [ngClass]="{ 'filled-and-valid': isFilledAndValid('link'), 'invalid': isInvalid('link') }">
  <!-- <img src="../../assets/images/new-icons/icons8-delete-100.png" class="cross-icon" alt="X"> -->
  <div
    *ngIf="profileForm.get('link').value && profileForm.get('link').hasError('invalidLink') && profileForm.get('link').touched"
    class="error-text mt-2"><img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px"
      width="16px"><span class="copy-message">Please enter a valid URL.</span></div>
  <label for="bio" class="body-mini mt-4 pt-1">Bio</label>
  <textarea class="p-3" maxlength="100" formControlName="bio" (input)="countWords()"
    placeholder="Enter some information about you " name="bio" cols="30" rows="4" [ngClass]="{ 'filled-and-valid': isFilledAndValid('bio'), 'invalid': isInvalid('bio') }"></textarea>
  <p class="body-mini d-flex justify-content-end">{{ wordCount? wordCount : 0 }}/ 100</p>

  <div class="d-flex">
    <button *ngIf="editProfileFlow" (click)="goBack()" class="mb-2 btn tv-btn-light p-bold" [class.p-2]="loadingButton"
      [disabled]="loadingButton" [ngClass]="loadingButton ? 'disabled' : ''">
      Cancel
  </button>
  <button (click)="updateProfileInfo()" class="mb-2 red-btn" [class.p-2]="loadingButton"
    [disabled]="(profileForm.get('name').invalid || selectedOption.label === 'Select option')"
    [ngClass]="(profileForm.get('name').invalid || selectedOption.label === 'Select option') ? 'disabled' : ''">
    <span class="loader-bar" *ngIf="loadingButton"></span>
    <span *ngIf="!loadingButton" class="p-bold text-inverse">Save</span>
  </button>
  </div>
</div>

<ng-template #profilePicOptionModal>
  <input class="mx-auto file-input" type="file" id="upload" (change)="showPreview($event.target.files)" accept="image/jpg,image/png,image/jpeg" />
<div class="text-center mb-3 pb-2">
  <h2 class="">Profile Picture</h2>
  <hr>
  <p><a>Choose a Picture</a></p>
  <hr>
  <p *ngIf="userImage"><a (click)="openDeletePicConfirmationModal(deletePicConfirmation)">Delete Profile Picture</a></p>
  <hr *ngIf="userImage">
  <p><a (click)="profilePicRef.hide()">Cancel</a></p>
</div>
</ng-template>

<ng-template #deletePicConfirmation>
  <div class="action-container">
    <div class="text-center mb-2">
        <p>Are you sure you want to delete your profile picture?</p>
    </div>
    <div class="btn-container pb-0">
      <button class="secondary-btn" aria-label="Open"(click)="deletePicRef.hide()">
        Cancel
      </button>
        &nbsp;
      <button class="btn tv-btn-red" *ngIf="!showLoader" aria-label="Open" (click)="deleteProfilePic()">
        Delete
      </button>
      <div class="loader-bar mx-2" *ngIf="showLoader"></div>
    </div>
  </div>
</ng-template>