<div [class.my-2]="listItem.isContinueReading" class="margin-bottom">
  <a *ngIf="isWebsite()" class="navigation-section" [class.read-comic]="listItem.isRead" (click)="getViewsForExternalLink()" [href]="comicAction" target="_blank">
    <img class="episode-img" [class.fade-image]="listItem.isRead" [src]="getImgUrl(listItem.image)" onError="this.src='../../../assets/images/new-icons/preview-image-not-available.jpg'">
    <div class="p-2 w-100 d-flex flex-wrap" [ngClass]="{'mx-1' : !isMobile }">
      <div class="d-flex w-100" [class.flex-row]="!listItem.unReadCount">
        <img *ngIf="listItem.isRead" class="mr-1 mt-1" src="../../assets/images/new-icons/icons8-double-tick-100-dark.png" width="16px" height="16px" alt="Read">
        <div class="p-bold title">{{listItem.isContinueReading ? listItem.isFirstEpisode ? 'First Episode' : 'Next Episode' : listItem.title}}</div>
        <span *ngIf="listItem.unReadCount && !listItem.isFirstEpisode" class="ml-2 circle" [ngClass]="{'oval mt-1' : listItem.unReadCount > 99}"><span class="number">{{listItem.unReadCount > 99 ? '99+': listItem.unReadCount}}</span></span>
        <img src="../../assets/images/new-icons/icons8-external-link-100.png" class="mt-0 ml-auto external-icon" alt="External Link">
      </div>
      <div class="align-self-end w-100">
        <div class="d-flex justify-content-between" *ngIf="listItem.isFirstEpisode || (!listItem.isContinueReading && !listItem.isFirstEpisode)">
          <span class="body-mini date" *ngIf="!isMobile">{{listItem.time}}</span>
          <span class="body-mini date" *ngIf="isMobile">{{listItem.time | date:'MMM d, y' }}</span>
          <span class="body-mini" [class.mobile-view]="isMobile">{{listItem.views | numberFormat}}
            <span><img [src]="viewIcon" class="viewIcon" alt="Views"></span>
            <!-- <span class="body-mini" *ngIf="!isMobile">{{listItem.views == '1' ? 'View' : 'Views'}}</span> -->
          </span>
        </div>
        <p *ngIf="listItem.isContinueReading && !listItem.isFirstEpisode" class="title body-mini">{{listItem.title}}</p>
      </div>
    </div>
  </a>
  <a *ngIf="!isWebsite()" class="navigation-section" [class.read-comic]="listItem.isRead" (click)="getImageAction()" [routerLink]="comicAction">
    <img class="episode-img" [class.fade-image]="listItem.isRead" [src]="getImgUrl(listItem.image)" onError="this.src='../../../assets/images/new-icons/preview-image-not-available.jpg'">
    <div class="p-2 w-100 d-flex flex-wrap" [ngClass]="{'mx-1' : !isMobile }">
      <div class="d-flex w-100" [class.flex-row]="!listItem.unReadCount">
        <img *ngIf="listItem.isRead" class="mr-1 mt-1" src="../../assets/images/new-icons/icons8-double-tick-100-dark.png" width="16px" height="16px" alt="Read">
        <div class="p-bold title">{{listItem.isContinueReading ? listItem.isFirstEpisode ? 'First Episode' : 'Next Episode' : listItem.title}}</div>
        <span *ngIf="listItem.unReadCount && !listItem.isFirstEpisode" class="ml-2 circle" [ngClass]="{'oval mt-1' : listItem.unReadCount > 99}"><span class="number">{{listItem.unReadCount > 99 ? '99+': listItem.unReadCount}}</span></span>
        <img *ngIf="isPremium" [src]="srcPremium" class="mt-1 ml-auto external-icon" alt="External Link">
      </div>
      <div class="align-self-end w-100">
        <div class="d-flex justify-content-between" *ngIf="listItem.isFirstEpisode || (!listItem.isContinueReading && !listItem.isFirstEpisode)">
          <span class="body-mini date" *ngIf="!isMobile">{{listItem.time}}</span>
          <span class="body-mini date" *ngIf="isMobile">{{listItem.time | date:'MMM d, y' }}</span>
          <span class="body-mini" [class.mobile-view]="isMobile">{{listItem.views | numberFormat}}
            <span><img [src]="viewIcon" class="viewIcon" alt="Views"></span>
            <!-- <span class="body-mini" *ngIf="!isMobile">{{listItem.views == '1' ? 'View' : 'Views'}}</span> -->
          </span>
        </div>
        <p *ngIf="listItem.isContinueReading && !listItem.isFirstEpisode" class="title body-mini">{{listItem.title}}</p>

      </div>
    </div>
  </a>
</div>
