import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AUTH_ERROR_MESSAGES } from '../constants/error-message.constants';
import { AUTH_TYPE } from '../constants/common.constants';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  public getFirebaseFunction(functionName: string): firebase.functions.HttpsCallable {
    return firebase.functions().httpsCallable(functionName);
  }

  public getUserID(): string {
    return (firebase.auth().currentUser && firebase.auth().currentUser.uid) || '';
  }

  public isUserAnonymous(): boolean {
    return (firebase.auth().currentUser && firebase.auth().currentUser.isAnonymous) || false;
  }

  public async sendEmailLink(email: string, emailLinkData) {
    if (!email) {
      throw new Error(AUTH_ERROR_MESSAGES.MISSING_EMAIL);
    }
    try {
      return await firebase.auth().sendSignInLinkToEmail(email, emailLinkData);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('errorCode', errorCode);
      console.log('errorMessage', errorMessage);
      throw Error(errorMessage); // handle error messages with error code from docs
    }
  }

  public async signInWithCustomToken(token: string) {
    try {
      if (!token) {
        throw new Error(AUTH_ERROR_MESSAGES.MISSING_CUSTOM_TOKEN);
      }

      return await firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      console.log('errorCode', errorCode);
      console.log('errorMessage', errorMessage);
    }
  }

  public async linkWithCredential(credentialData) {
    try {
      if (!credentialData.authType || !credentialData.authValue) {
        throw new Error(AUTH_ERROR_MESSAGES.MISSING_AUTHTYPE_AUTHVALUE);
      }

      let credential;

      switch (credentialData.authType) {
        case AUTH_TYPE.EMAIL:
          credential = firebase.auth.EmailAuthProvider.credentialWithLink(credentialData.authValue, credentialData.emailLink);
          break;
        case AUTH_TYPE.PHONE:
          credential = firebase.auth.PhoneAuthProvider.credential(credentialData.authValue, credentialData.code);
          break;
        default:
          throw new Error(AUTH_ERROR_MESSAGES.MISSING_EMAIL_OR_PHONE);
      }
      await firebase.auth().currentUser.linkWithCredential(credential)
    } catch (err) {
      let verifyCodeErr = 'Something went wrong, Please try again!';
      if (err.code == 'auth/too-many-requests') {
        verifyCodeErr = 'Too many request from the same device  try again later.';
      } else if (err.code == 'auth/network-request-failed') {
        verifyCodeErr = 'Oops! Something is not right. Please check your internet connection.';
      } else if (err.code == 'auth/code-expired') {
        verifyCodeErr = 'The SMS code has expired. Please re-send the verification code to try again.';
      } else if (err.code == 'auth/invalid-verification-code') {
        verifyCodeErr = 'Invalid verification code.';
      } else if (err) {
        verifyCodeErr = err.message;
      }
      const errorCode = err.code;
      const errorMessage = err.message;
      console.log('errorCode', errorCode);
      console.log('errorMessage', errorMessage);
      throw Error(verifyCodeErr); // handle error messages with error code from docs
    }
  }

  public async signInWithEmail(email: string, emailLink: string) {
    try {
      return await firebase.auth().signInWithEmailLink(email, emailLink);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  public renderRecaptcha(id: string) {
    return new firebase.auth.RecaptchaVerifier(id, {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved - allow the user to proceed with authentication
        console.log('reCAPTCHA solved');
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        console.log('reCAPTCHA expired');
      }
    });
  }

  // all fb error code should be handled here
}
