import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';
import { ComicService } from '../services/comic.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StoryFeedService } from '../services/story-feed.service';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'

      })),
      state('out', style({
        transform: 'translate3d(100%,0,0)',
        'visibility': 'hidden'
      })),
      transition('in => out', animate('1ms ease-in-out')),
      transition('out => in', animate('1ms ease-in-out'))
    ]),
  ]
})
export class HeaderComponent implements OnInit {
  @ViewChild(MenuComponent, { static: false }) chviewChild: MenuComponent;
  seriesName: string
  refType: string
  open: boolean = false;
  eRef: any;
  clickedOutside: boolean = true;
  enableHome: any;
  userImage: any;
  userName: any;
  userBadges: any;
  userPhoneNumber: any;
  currentDevice: string;
  menuState: string = 'out';
  isMenuOpen: boolean = true;
  name: string;
  param: string;
  currentUrl: string;
  seriesName1: string;
  refType1: string;
  formattedSeries: any;
  manageSubscriptionObj: any;
  isMobileView: boolean;
  isSubscribed: boolean;

  constructor(
    public feedService: StoryFeedService,
    private route: ActivatedRoute,
    public readonly comicService: ComicService,
    private readonly router: Router,
    private authService: AuthService,
    private _location: Location
  ) {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
    this.param = this.route.snapshot.paramMap.get('ID');
    this.currentDevice = this.comicService.getOperatingSystem();
    if (this.param) {
      this.feedService.getStoryByID(this.param).then(async resp => {
        this.getSeries(resp.data.data.series)
        this.refType = resp.data.data.refType;
        this.seriesName = this.formattedSeries ? this.formattedSeries : `Tinyview`;
      });
    }
  }

  ngOnInit() {
    this.open = false;
    if (window.innerWidth <= 991) {
      this.isMobileView = true;
    }
    if (this.comicService.userDetails) {
      this.setUserDetails(this.comicService.userDetails);
    }
    this.comicService.$userDetails.subscribe(res => {
      this.setUserDetails(res);
    })
  }

  private setUserDetails(user) {
    this.userImage = user.data.photoURL;
    this.userName = user.data.displayName;
    this.userBadges = user.data.badges;
    this.userPhoneNumber = user.data.phoneNumber ? user.data.phoneNumber.substr(user.data.phoneNumber.length - 4) : null;
    const subDetails = user.data.subscriptions.length && user.data.subscriptions[0];
    this.isSubscribed = true;
    switch (subDetails && subDetails.store.toLowerCase()) {
      case "stripe":
        this.comicService.createPortalLink().then((url) => {
          if (url) {
            this.manageSubscriptionObj = { url: url, target: "_blank" };
          }
        }).catch((err) => {
          console.log(err);
        })
        break;
      case "apple":
      case "ios":
        this.manageSubscriptionObj = { url: "https://apps.apple.com/account/subscriptions", target: "_blank" };
        break;
      case "android":
      case "google":
        this.manageSubscriptionObj = { url: "https://play.google.com/store/account/subscriptions", target: "_blank" };
        break;
      default:
        this.manageSubscriptionObj = { url: "tinyview/subscribe", target: "_self", internalRedirection: true };
        this.isSubscribed = false;
        break;
    }
  }

  public getSeries(series) {
    this.comicService.getComicTitle().subscribe(res => {
      for (var key in res) {
        if (res[key].title == series) {
          this.formattedSeries = key;
        }
        if (key == series) {
          this.formattedSeries = res[key].title;
        }
      }
    });
  }

  outsideClick(hasClickedOutside) {
    if (this.menuState == 'in') {
      this.name = hasClickedOutside ? 'clicked inside' : 'clicked outside';
      if (this.name == 'clicked outside') {
        this.menuState = 'out'
      }
    }
  }

  public parentCall() {
    this.chviewChild.ngOnInit();
  }

  public goToHome() {
    // if (this.enableHome) {
    this.router.navigate(['']);
    // }
  }

  public toggleMenu() {
    this.menuState = this.menuState == 'out' ? 'in' : 'out';
    this.clickedOutside = false;
  }

  public navigateTo(page: string) {
    let url = '';
    if (page == 'back') {
      if (window.history.length > 1) {
        this._location.back();
      }
      return;
    }
    if (page == 'directory') {
      url = '/tinyview/comic-series-directory';
    } else if (page == 'following') {
      //this.comicService.showAllComics$.next(false);
      url = '/followings-comics';
    }
    this.router.navigate([url]);
  }

  async signOut() {
    await this.authService.signOut();
  }
}
