<div *ngIf="comic" class="p-0" [ngClass]="{'tv-card': !storyPage, 'pb-3': !storyPage && !isCreatorUI && comicData.commentCount, 'pb-1-px': isCreatorUI}">
  <div class="toc-panel-wrapper">
    <div class="middle-section" *ngIf="comicData.image">
      <a *ngIf="comicData && !isWebsite() && comicData.image" (click)="getImageAction()"
        style="text-decoration: none;">
        <img *ngIf="!uploadURL" [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage, 'fade-image': comicData.isRead}"
          [ngStyle]="getImageBorder()" alt="panel image"
          onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'"
          [ngClass]="{'story-img': !storyPage, 'fade-image': comicData.isRead}">
        <img *ngIf="uploadURL" class="story-img" [src]="uploadURL" alt="panel image">
        <div class="mx-20 mt-2" *ngIf="!storyPage && comicData.isRatingDataReady">
          <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
        </div>
        <div class="d-flex justify-content-between">
          <h2 class="title-head mt-1 pt-2 mx-20">{{ comicData.title | truncate:100 }}</h2>
          <div *ngIf="showReadTicks || (!comicData.isRatingDataReady && comicData.isRead)" class="pt-2 mr-4">
            <img src="../../assets/images/new-icons/icons8-double-tick-100.png" width="20px" height="20px" alt="Read">
          </div>
        </div>

        <!-- <div class="story-bottom">
          <span> {{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br/>
          <span class="story-title">{{comicData?.title}}</span><br/>
        </div> -->
      </a>
      <a (click)="getViewsForExternalLink()" *ngIf="isWebsite()" [href]="comicAction" style="text-decoration: none;" target="_blank">
        <img *ngIf="!uploadURL" [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage, 'fade-image': comicData.isRead}"
          alt="panel image" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
        <img *ngIf="uploadURL" class="story-img" [src]="uploadURL" alt="panel image">
        <div class="mx-20 mt-2" *ngIf="!storyPage && comicData.isRatingDataReady">
         <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
        </div>
        <div class="d-flex mt-1 pt-2 mx-20 justify-content-between" *ngIf="isWebsite() && !storyPage">
          <h2 class="title-head mr-3">{{ comicData.title | truncate:100 }}</h2>
          <img *ngIf="showReadTicks || (!comicData.isRatingDataReady && comicData.isRead)" class="mr-3 my-auto" [class.mt-2]="storyPage" alt="Read" height="20px"
            src="../../assets/images/new-icons/icons8-double-tick-100.png" width="20px">
          <a class="btn tv-btn-light ext-btn body-mini d-flex align-items-center justify-content-center">
            <img src="../../assets/images/new-icons/icons8-external-link-100.png" width="16px" height="16px" class="mr-1" alt="External Link">
            <span class="body-mini">{{externalButtonText}}</span>
          </a>
        </div>
        <!-- <div class="story-bottom">
          <span>{{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br/>
          <span class="story-title">{{comicData?.title}}</span><br/>
        </div> -->
      </a>
      <div class="top-container" [class.mt-2]="storyPage" *ngIf="storyPage">
        <a *ngIf="comicData?.user?.image && !storyPage" (click)="onImageClick()" class="pt-3">
          <div class="user-img-wrapper">
            <img class="user-img" [src]="getImgUrl(comicData.user)" alt="user's image"
            onError="this.src='../../assets/images/male-user-100.png'">
          </div>
        </a>
        <a *ngIf="(comicData && !comicData?.user?.image) && comicData?.user?.name && !storyPage" (click)="onImageClick()" class="pt-3">
          <div class="user-img-wrapper2">
            <p>{{getFirstLetters(comicData.user.name)}}</p>
          </div>
        </a>
        <div class="user-name-wrapper w-100" *ngIf="comicData?.image" [class.mt-3]="!storyPage">
          <a *ngIf="comicData?.user?.name && !isWebsite()" (click)="onImageClick()">
            <h2 class="user-name">{{comicData?.user.name}}
              <span class="user-badges" *ngIf="comicData?.user?.badges && comicData?.user?.badges[0]">
                <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comicData.user.badges[0])">
              </span>
            </h2>
          </a>
          <a *ngIf="comicData?.series && isWebsite()" [routerLink]="['/'+ comicData.series]">
            <h2>{{formatSeriesName(comicData.series)}}</h2>
          </a>
          <!-- TODO: PB: Move this margins and paddings inside comic-rating for story page -->
          <div class="mt-1 mb-2" *ngIf="storyPage && comicData.isRatingDataReady">
            <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
          </div>
          <a *ngIf="comicData?.title && isWebsite()" class="title-with-read" (click)="openLinkInNewTab(comicAction)"
            [ngClass]="{'mt-12': storyPage}">
            <div class="user-name p-bold mr-3">{{comicData.title | truncate:100 }}</div>
            <img *ngIf="showReadTicks || (!comicData.isRatingDataReady && comicData.isRead)" class="mr-3 mt-1" alt="Read" height="20px"
              src="../../assets/images/new-icons/icons8-double-tick-100.png" width="20px">
          </a>
          <p class="story-comic-date story-page body-mini" *ngIf="comicData.datetime || comicData.createdAt">{{comicService.formatDate(comicData.datetime || comicData.createdAt)}}</p>
        </div>
        <button *ngIf="storyPage && !isWebsite()" class="btn tv-btn-light series-btn body-mini story-series-btn" (click)="getImageAction()">
          View Comic
        </button>
        <button *ngIf="storyPage && isWebsite()" class="body-mini story-series-btn btn tv-btn-light ext-btn body-mini d-flex align-items-center justify-content-center" [class.no-series-name]="!comicData.series" (click)="openLinkInNewTab(comicAction)">
          <img src="../../assets/images/new-icons/icons8-external-link-100.png" width="16px" height="16px" class="mr-1" alt="External Link">
          {{ externalButtonText }}
        </button>
      </div>
      <!-- <div *ngIf="storyPage && (comicData.comments || comicData.comment)">
        <div (click)="getCommentAction()" class="story-comments cursor-pointer comments" [innerHTML]="(comicData.comments || comicData.comment) | stripTags: 'br' :'p' :'i' :'b'"
        [ngClass]="{ 'story-page-comments': storyPage }"></div>
      </div> -->
      <div class="top-section mb-2 mx-20" *ngIf="!storyPage">
        <div class="user-name-wrapper" *ngIf="(comicData.datetime || comicData.createdAt)">
          <p class="story-comic-date body-mini">{{comicService.formatDate(comicData.datetime || comicData.createdAt)}}</p>
        </div>
        <div *ngIf="comicData.comments || comicData.comment">
          <!-- <img src="../../assets/images/new-icons/icons8-quote-50.png" class="mt-2 quotes ml-0" alt="Quotes"> -->
          <div (click)="getCommentAction()" class="story-comments cursor-pointer comments" [innerHTML]="(comicData.comments || comicData.comment) | stripTags: 'br' :'p' :'i' :'b'"
          [ngClass]="{ 'story-page-comments': storyPage }"></div>
        </div>
      </div>
    </div>
    <app-action-bar [viewCount]="comicData?.views" *ngIf="!hideBottomSection && !loadingStory && (comicData?.image && comicData?.template != 'stories' && !storyPage)"
      [comicAction]="comicData?.action"  [isLike]="comicData?.isLiked" [commentCount]="comicData.commentCount" [repostCount]="comicData?.repostCount"
      [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID" [giftItemCount]="comicData?.giftsCount" [storyData]="comicData"
      [hideCommentSection]="isComicPage" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
      [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
    </app-action-bar>
    <app-comic-buttons *ngIf="!hideBottomSection" [comicData]="comicData"></app-comic-buttons>
  </div>
  <app-action-bar [viewCount]="comicData?.views" *ngIf="!hideBottomSection && comicData?.image && comicData?.template != 'stories' && storyPage"
    [comicAction]="comicData?.action"  [isLike]="comicData?.isLiked" [commentCount]="comicData.commentCount" [repostCount]="comicData?.repostCount"
    [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID" [giftItemCount]="comicData?.giftsCount" [storyData]="comicData"
    [hideCommentSection]="isComicPage" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
    [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
  </app-action-bar>
</div>