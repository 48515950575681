<div class="see-friend-container">
  <div class="open-in-app pl-4 pr-3 py-2 justify-content-md-between" *ngIf="isInviteCard">
    <div class="my-auto">
      <span class="toast-text body-mini" *ngIf="isAnonymousUser">You must sign in to see or invite friends</span>
      <span class="toast-text body-mini" *ngIf="!isSubscribed && !isAnonymousUser">Earn <span class="body-mini-bold toast-text">5 influence points</span> for every invite accepted!</span>
      <span class="toast-text body-mini" *ngIf="!isAnonymousUser && isSubscribed">See likes and comments of your friends</span>
    </div>
    <div class="my-auto ml-auto">
      <button *ngIf="isAnonymousUser" class="ml-auto tv-btn-red btn body-mini" [routerLink]="['/signin/phone/number']">Sign In</button>
      <button *ngIf="!isAnonymousUser && !isSubscribed" class="ml-auto body-mini tv-btn-red btn" (click)="openInviteFriendsModal(inviteFriendsModal)">Invite Friends</button>
    </div>
    <div class="text-nowrap my-auto" >
      <a (click)="closeInviteFriendCard()" class="ml-3"><img src="../../assets/images/new-icons/icons8-delete-100.png" alt="x" width="12px" height="12px" class="toast-text-png"></a>
    </div>
  </div>

  <div class="d-flex justify-content-between mx-4 mt-3 mb-2">
    <h1 class="">Friend Requests</h1>
    <div class="d-flex mt-1 cursor-pointer" (click)="openInviteFriendsModal(inviteFriendsModal)">
      <img src="../../assets/images/new-icons/icons8-add-user-group-man-man-100.png" height="18px" width="18px" alt="Invite Friends">
      <p class="ml-2">Invite Friends</p>
    </div>
  </div>
  <div class="d-flex mt-3 mx-4" *ngIf="!isAnonymousUser">
    <a (click)="navigateTo('received')" class="mr-4">
      <h2 [class.selectedTab]="activeTab === 'received'" [class.unSelectedTab]="activeTab !== 'received'">Invite{{totalRequestsReceived !== 1 ? 's' : ''}} Received ({{totalRequestsReceived || 0}})</h2>
    </a>
    <a (click)="navigateTo('sent')">
      <h2 [class.selectedTab]="activeTab === 'sent'" [class.unSelectedTab]="activeTab !== 'sent'">Invite{{totalRequestsSent !== 1 ? 's' : ''}} Sent ({{totalRequestsSent || 0}})</h2>
    </a>
  </div>
  <div *ngIf="isLoading" class="mx-4 mt-4">
    <img src="../../../assets/images/new-icons/friends-placeholder.gif" width="450px" alt="Loading">
  </div>
  <div class="mx-4 my-4 friend-list" *ngIf="friendList.length !== 0 && !isLoading">
    <div class="d-flex my-auto px-3 py-2" *ngFor="let item of friendList; let i = index" [class.separator]="i !== friendList.length -1">
      <span class="my-auto">
        <img *ngIf="item.userProfilePic" [src]="item.userProfilePic" alt="User Image" class="images" [class.image-border]="!!item.userProfilePic">
        <div class="img-placeholder my-auto" *ngIf="!item.userProfilePic">
          <p class="body-mini">{{getFirstLetters(item.name)}}</p>
        </div>
      </span>
      <p class="my-auto ml-2 name-mobile" [class.body-mini]="isMobile">{{item.name}}</p>
    <div class="d-flex ml-auto my-1">
      <p (click)="actionOnRequest($event, 'accepted', item)" class="btn tv-btn-red body-mini text-white mr-2" [class.btn-mobile]="isMobile">
        {{getButton(activeTab)}}
      </p>
      <button (click)="actionOnRequest($event,'delete', item)" class="btn tv-btn-light" [class.btn-mobile]="isMobile">
        <p class="body-mini">Delete</p>
      </button>
    </div>
    </div>
  </div>
  <p class="mx-4 my-4" *ngIf="!isAnonymousUser && friendList.length === 0 && activeTab === 'sent'">You haven’t sent any invites</p>
  <p class="mx-4 my-4" *ngIf="!isAnonymousUser && friendList.length === 0 && activeTab === 'received'">You haven't received any invites</p>

  <!-- Why Invite Image & Influence Cards -->
  <div class="mx-4">
    <img [src]="whyInviteImage" alt="Invite Image">
  </div>
  <div class="mx-1 pb-5">
    <ng-container *ngFor="let card of influenceCards">
      <app-influence-card *ngIf="true" [cardData]="card"
      [storyAction]="''" [storyID]="''">
      </app-influence-card>
    </ng-container>
  </div>
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" [receiverPhoneNumber]="receiverPhoneNumber" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>
