<div class="container px-2" *ngIf="!error">
  <div class="mt-1">
    <h1 class="mt-4 pt-2">Hi <span *ngIf="userName">, {{userName}}</span></h1>
  </div>
  <div class="row">
    <div class="col-auto d-flex custom-filter-margin">
      <app-series-filter (sendFilterData)="onDataReceived($event)" [isLoading]="isLoading"></app-series-filter>
    </div>
    <div *ngIf="tinyViewAggregates && !isLoading && !tableError" class="ml-2">
      <div class="mt-2">
        <p class="pl-3 mb-2 pb-1 text-start">{{calculatedFor}}</p>
      </div>
      <div class="col-auto p-0 pl-1 ml-3 total-stat-container">
        <app-page-view-revenue [tinyViewAggregates]="tinyViewAggregates"></app-page-view-revenue>
      </div>
    </div>
  </div>
  <app-creator-stat-table *ngIf="!tableError && !isLoading" [tableHead]="tableHead" [calculatedDailyDataView]="calculatedDailyDataView" [monthlyAggregateView]="monthlyAggregateView"></app-creator-stat-table>
  <div class="h3 text-center mt-5" *ngIf="tableError">
    {{tableError}}
  </div>
  <div class="loader-container" *ngIf="isLoading">
    <img class="loader-content" src="../../assets/images/new-icons/icons8-loading-v2.gif" alt="loader">
  </div>
</div>

<div class="container h3 text-center mt-5" *ngIf="error">
  {{error}}
</div>
