<div class="see-friend-container">
  <div class="open-in-app pl-4 pr-3 py-2 justify-content-md-between" *ngIf="isInviteCard">
    <div class="my-auto">
      <span class="toast-text body-mini" *ngIf="!isSubscribed && !isAnonymousUser">Earn <span class="body-mini-bold toast-text">5 influence points</span> for every invite accepted!</span>
      <span class="toast-text body-mini" *ngIf="isAnonymousUser">You must sign in to see or invite friends</span>
      <span class="toast-text body-mini" *ngIf="!isAnonymousUser && isSubscribed">See likes and comments of your friends</span>
    </div>
    <div class="my-auto ml-auto">
      <button *ngIf="isAnonymousUser" class="ml-auto body-mini tv-btn-red btn" [routerLink]="['/signin/phone/number']">Sign In</button>
      <button *ngIf="!isAnonymousUser && !isSubscribed" class="ml-auto body-mini tv-btn-red btn" (click)="openInviteFriendsModal(inviteFriendsModal)">Invite Friends</button> <!-- Add Invite Friend here -->
    </div>
    <div class="text-nowrap my-auto" >
      <a (click)="closeInviteFriendCard()" class="ml-3"><img src="../../assets/images/new-icons/icons8-delete-100.png" alt="x" width="12px" height="12px" class="toast-text-png"></a>
    </div>
  </div>

  <div class="d-flex justify-content-between mx-4 mt-3 mb-1">
    <h1>Friends <span *ngIf="!isAnonymousUser">({{friendList.length || 0}})</span></h1>
    <div class="d-flex mt-1 cursor-pointer" [class.not-allowed]="isAnonymousUser" (click)="openInviteFriendsModal(inviteFriendsModal)">
      <img src="../../assets/images/new-icons/icons8-add-user-group-man-man-100.png" height="18px" width="18px" alt="Invite Friends">
      <p class="ml-2">Invite Friends</p>
    </div>
  </div>
  <div *ngIf="isLoading" class="mx-4">
    <img src="../../../assets/images/new-icons/friends-placeholder.gif" width="450px" alt="Loading">
  </div>
  <div class="mx-4 friend-list mb-4" *ngIf="friendList.length !== 0">
    <div class="d-flex px-3 py-2" *ngFor="let item of friendList; let i = index" [class.separator]="i !== friendList.length -1">
      <span class="my-auto">
        <img *ngIf="item.userProfilePic" [src]="item.userProfilePic" alt="User Image" [class.image-border]="!!item.userProfilePic" class="images">
        <div class="img-placeholder" *ngIf="!item.userProfilePic">
          <p class="body-mini">{{getFirstLetters(item.name)}}</p>
        </div>
      </span>
      <p class="my-auto ml-2" [class.body-mini]="isMobile">{{item.name}}</p>
      <a (click)="openUnfriendConfirmationModal(unfriendConfirmation, item)" class="ml-auto btn tv-btn-light" [class.btn-mobile]="isMobile">
        <p [class.body-mini]="isMobile" [class.my-1]="isMobile">Unfriend</p>
      </a>
    </div>
  </div>
  <p class="mx-4 mt-2 mb-3" *ngIf="!isAnonymousUser && friendList.length === 0">You don’t have any friends yet</p>

  <!-- Why Invite Image & Influence Cards -->
  <div class="mx-4">
    <img [src]="whyInviteImage" alt="Invite Image">
  </div>
  <div class="mx-1 pb-5">
    <ng-container *ngFor="let card of influenceCards">
      <app-influence-card *ngIf="true" [cardData]="card"
      [storyAction]="''" [storyID]="''">
      </app-influence-card>
    </ng-container>
  </div>
</div>
<!-- Modals -->
<ng-template #unfriendConfirmation>
  <div class="text-center m-3">
    <h2>Unfriend {{unfriendUserName}}?</h2>
    <p class="my-3">Are you sure you want to remove {{unfriendUserName}} as your friend?</p>
    <div class="d-flex justify-content-center">
      <a class="mx-2 border-button px-2 py-1" (click)="closeUnfriendConfirmationModal()">Cancel</a>
      <button class="ml-3 px-3" (click)="unfriendConfirm(unfriendUID)"><span *ngIf="!isLoadingButton">Unfriend</span><div class="loader-bar-btn" *ngIf="isLoadingButton"></div></button>
    </div>
  </div>
</ng-template>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>
