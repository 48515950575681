<h1 *ngIf="currentFlow === 'SIGNUP'">Enter Verification Code</h1>

<p class="my-20">
    {{otpSentMsg}}
</p>

<p class="my-20 mb-0">Verification code*</p>

<!-- OTP form started -->
<form [formGroup]="verificationForm" (keydown.enter)="verifyCode(verificationForm, $event)">
    <div class="form-group text-center" [ngClass]="{'mb-0': verifyCodeErr}">
        <input type="text" class="verification-code" maxlength="6" mask="0" formControlName="digit1"
            (keyup)="otpKeytab($event, 'digit1')" id="firstInput" inputmode="numeric" pattern="\d*"
            (input)="onInput($event)"
            [ngClass]="{'input-value-present': verificationForm.value.digit1, 'input-value-invalid': verifyCodeErr}" />
        <input type="text" class="verification-code" maxlength="1" mask="0" formControlName="digit2"
            (keyup)="otpKeytab($event, 'digit2')" inputmode="numeric" pattern="\d*"
            [ngClass]="{'input-value-present': verificationForm.value.digit2, 'input-value-invalid': verifyCodeErr}" />
        <input type="text" class="verification-code" maxlength="1" mask="0" formControlName="digit3"
            (keyup)="otpKeytab($event, 'digit3')" inputmode="numeric" pattern="\d*"
            [ngClass]="{'input-value-present': verificationForm.value.digit3, 'input-value-invalid': verifyCodeErr}" />
        <input type="text" class="verification-code" maxlength="1" mask="0" formControlName="digit4"
            (keyup)="otpKeytab($event, 'digit4')" inputmode="numeric" pattern="\d*"
            [ngClass]="{'input-value-present': verificationForm.value.digit4, 'input-value-invalid': verifyCodeErr}" />
        <input type="text" class="verification-code" maxlength="1" mask="0" formControlName="digit5"
            (keyup)="otpKeytab($event, 'digit5')" inputmode="numeric" pattern="\d*"
            [ngClass]="{'input-value-present': verificationForm.value.digit5, 'input-value-invalid': verifyCodeErr}" />
        <input type="text" class="verification-code" maxlength="1" mask="0" formControlName="digit6"
            (keyup)="otpKeytab($event, 'digit6')" inputmode="numeric" pattern="\d*"
            [ngClass]="{'input-value-present': verificationForm.value.digit6, 'input-value-invalid': verifyCodeErr}" />
    </div>

    <!-- Error block which will be highlighted in red color -->
    <div *ngIf="verifyCodeErr" class="body-mini error-text mt-1 mb-3 lh-18">
        <img src="../../assets/images/new-icons/icons8-exclamation-100.png" class="error-icon">
        &nbsp;{{verifyCodeErr}}
        <br>
        <span class="tv-link" (click)="resend()">Resend</span>

        &nbsp;|&nbsp;
        <span class="tv-link" (click)="changeNumber()">Change number</span>
    </div>
    <!-- Block to resend or change the number -->
    <p *ngIf="!verifyCodeErr" [ngClass]="{'d-flex': !isMobile}">Didn’t get the code? &nbsp;
        <br *ngIf="isMobile">
        <span class="tv-link p-0" (click)="resend()">Resend</span>
        &nbsp;|&nbsp;
        <span class="tv-link" (click)="changeNumber()">Change number</span>
    </p>


    <!-- Button to verify the code -->
    <div class="form-group">
        <button type="submit" [disabled]="verificationForm.invalid || verifyCodeErr"
            class="btn tv-btn-red send-btn" (click)="verifyCode(verificationForm, $event)" id="resend-otp">
            <span class="loader-bar" *ngIf="!codefetchDone"></span>
            <span class="p-bold send-btn-text" *ngIf="codefetchDone">Verify</span>
        </button>
    </div>
</form>
