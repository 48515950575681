import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComicService } from '../services/comic.service';
import { PanelService } from '../services/panel/panel.service';
import { StoryFeedService } from '../services/story-feed.service';
import { latestComics } from '../constants/common.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from '../../environments/environment';
const COMIC_IMG_API = `${environment.API_END_POINT}`;
@Component({
  selector: 'app-comic-episodes',
  templateUrl: './comic-episodes.component.html',
  styleUrls: ['./comic-episodes.component.scss', './../comic/comic.component.scss']
})
export class ComicEpisodesComponent implements OnInit {
  @Input() currentComicImage: any;
  @Input() comicData: any;
  @Input() indexOfelement: any;
  isFromFollowing: boolean;
  public comicPanels: any = [];
  currentUrl: string;
  public scrollIndex: number;
  comicIndex: any;
  resp: any;
  panelData: any = [];
  currentDevice: string;
  panelSize: any;
  srollvalue: any;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  disableNextBtn = false;
  disablePreviousBtn = false;
  previousEpisodeImage: any;
  nextEpisodeImage: string;
  currentEpisodeImage: string;
  action: any;
  series: any;
  bottom: any;
  next: any;
  top: any;
  previous: any;
  storyID: any;

  constructor(
    private readonly router: Router,
    public feedService: StoryFeedService,
    public comicService: ComicService,
    private localStorageService: LocalStorageService,
    public panelService: PanelService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
  }
  ngOnInit() {
    this.currentDevice = this.comicService.getOperatingSystem();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.getNavigation();

    // this.comicService.getComicChaptersForEpisodes(this.comicData.action.replace('/index.json', ''), '').subscribe(resp => {
    // this.panelSize = resp.comics.panels.length;
    // this.getComicPanels(resp.comics.panels);
    // });
  }

  async getNavigation() {
    this.action = `${this.currentUrl}/index.json`;
    this.series = this.currentUrl.split('/')[1];
    const prevPage = this.localStorageService.getItem('selectedMenuItem');
    prevPage === 'following' ? this.isFromFollowing = true : this.isFromFollowing = false;
    this.storyID = this.localStorageService.getItem('storyID');
    if (prevPage !== 'following' && prevPage !== 'home') {
      this.storyID = '';
    }
    const input = {
      forFeedPage: this.isFromFollowing,
      storyID: this.storyID ? this.storyID : '',
      series: this.series,
      action: this.action
    };
    const res = await this.comicService.getComicNavigation(input);
    this.bottom = res.data.bottom ? this.mapData(res.data.bottom) : this.mapData('bottom');
    this.next = res.data.next ? this.mapData(res.data.next) : this.mapData('no-episodes');
    this.top = res.data.top ? this.mapData(res.data.top) : this.mapData('top');
    this.previous = res.data.previous ? this.mapData(res.data.previous) : this.mapData('no-episodes');
    this.currentEpisodeImage = this.panelService.getImgUrl(this.currentComicImage, this.currentUrl);
  }

  mapData(data) {
    if (data === 'top') {
      return {
        image: `${COMIC_IMG_API}/tinyview/app/top-of-feed-preview.jpg`,
        action: null
      };
    } else if (data === 'bottom') {
      return {
        image: `${COMIC_IMG_API}/tinyview/app/bottom-of-feed-preview.jpg`,
        action: null,
      };
    } else if (data === 'no-episodes') {
      return {
        image: `${COMIC_IMG_API}/tinyview/app/no-more-episodes-preview.jpg`,
        action: null
      };
    } else {
      data.image = this.panelService.getImgUrl(data.image, this.currentUrl);
      data.id = data.id;
      return data;
    }
  }

  navigateOnClick(data) {
    if (data.actionType != "website") {
      data.id ? this.localStorageService.setItem('storyID', data.id) : null;
      this.router.navigate([data.action.replace('index.json', '')])
    } else {
      this.comicService.viewsForExternalLink(data.action, 1);
      window.open(data.action, "_blank");
    }
  }
  onImageError(data?) {
    if (!data) {
      this.currentEpisodeImage = `${COMIC_IMG_API}/tinyview/app/bottom-of-feed-preview.jpg`;
    } else {
      data.image = `${COMIC_IMG_API}/tinyview/app/bottom-of-feed-preview.jpg`;
    }
  }
}
