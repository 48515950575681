import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { signInPhoneURL, signUpURL } from '../constants/common.constants';
import { LoggedOutModalData } from '../interfaces/user.interface';
import { EventService } from '../services/event.service';
import { LOGGED_OUT_MODAL_BODY_MESSAGES, LOGGED_OUT_MODAL_BUTTON_PROPERTIES } from '../constants/user.constants';

@Component({
  selector: 'app-logged-out-modal',
  templateUrl: './logged-out-modal.component.html',
  styleUrls: ['./logged-out-modal.component.scss']
})
export class LoggedOutModalComponent implements OnInit {
  @Input() modalData: LoggedOutModalData;
  public body = LOGGED_OUT_MODAL_BODY_MESSAGES.ACCOUNT_MERGED;
  public btn1Text = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.CLOSE.TEXT;
  public btn1Action = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.CLOSE.ACTION;
  public btn2Text = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_IN_AGAIN.TEXT;
  public btn2Action = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_IN_AGAIN.ACTION;
  isLoading: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    private authService: AuthService,
    private router: Router,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.checkFlow(this.modalData);
  }

  private checkFlow(data: LoggedOutModalData) {
    if (data.userMerged) {
      this.body = LOGGED_OUT_MODAL_BODY_MESSAGES.ACCOUNT_MERGED;
      this.btn2Text = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_IN_AGAIN.TEXT;
      this.btn2Action = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_IN_AGAIN.ACTION;
    } else if (data.userDeleted) {
      this.body = LOGGED_OUT_MODAL_BODY_MESSAGES.ACCOUNT_DELETED;
      this.btn2Text = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_UP_AGAIN.TEXT;
      this.btn2Action = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.SIGN_UP_AGAIN.ACTION;
    }

    // Close button will always be there & identical in both cases, these properties are already assigned while initialization
    // this.btn1Text = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.CLOSE.TEXT;
    // this.btn1Action = LOGGED_OUT_MODAL_BUTTON_PROPERTIES.CLOSE.ACTION;
  }

  public async action(btnActionType: string) {
    this.isLoading = true;
    try {
      switch (btnActionType) {
        case 'signOutAndClose':
          await this.signOutAndClose();
          break;
        case 'signOutAndRedirectToSignIn':
          await this.signOutAndRedirectToSignIn();
          break;
        case 'signOutAndRedirectToSignUp':
          await this.signOutAndRedirectToSignUp();
          break;
        default:
          await this.signOutAndClose();
      }
      this.isLoading = false;
    } catch (e) {
      console.log('Error while taking action on logged out modal: ', e);
      this.isLoading = false;
    }
  }


  public closeModal() {
    this.bsModalRef.hide();
  }

  // Used for Cancel btn
  public async signOutAndClose() {
    await this.signOut();
    this.closeModal();
    this.navigateTo('');
  }

  // Used for Sign In Again btn
  public async signOutAndRedirectToSignIn() {
    await this.signOut();
    this.closeModal();
    this.navigateTo(signInPhoneURL);
  }

  // Used for Sign Up Again btn
  public async signOutAndRedirectToSignUp() {
    await this.signOut();
    this.closeModal();
    this.navigateTo(signUpURL);
  }

  // Used for clearing logged in user data and then signing out from firebase
  public async signOut() {
    this.eventService.clearUserDataEvent.emit();
    await this.authService.signOut(false);
  }

  public navigateTo(url: string) {
    this.router.navigate([url]).then(() => {
      window.location.reload();
    });
  }
}
