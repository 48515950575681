import { EventEmitter, Injectable } from '@angular/core';
import { LoggedOutModalData } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public showLoggedOutModalEvent = new EventEmitter<LoggedOutModalData>();
  public clearUserDataEvent = new EventEmitter<void>();
  // Add more events as needed

  constructor() { }
}
