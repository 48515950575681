    <div class="modal-content">
      <div class="d-flex justify-content-between mb-3">
        <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="modalLinkRef.hide()" />
        <h2>Add Link</h2>
      </div>
     <div>
      <div class="body-mini">URL</div>
      <div class="d-flex">
        <input type="text" class="url" [formControl]="linkForm.get('url')" (keyup.enter)="addLink()" [class.invalid]="linkForm.get('url').hasError('urlFetchError')" placeholder="Add Link">
        <div class="ml-auto">
          <button type="button" class="btn tv-btn-red fetch-btn" value="Click" [disabled]="linkForm.get('url').hasError('required')" (click)="addLink()">Fetch</button>
        </div>
      </div>
      <div class="error-text mt-2" *ngIf="linkForm.get('url').hasError('urlFetchError') && linkForm.get('url').touched">
        <img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px">
        <span class="copy-message">Cannot fetch the URL.</span>
      </div>
     </div>
      <div *ngIf="linkFetch" class="loader"></div>
      <ng-container *ngIf="linkUpload">
        <div class="mt-3 pt-1">
          <div class="image-error-conatiner" *ngIf="imageErrorInfo && imageErrorInfo.message">
            <div class="text-center">
              <div *ngIf="imageErrorInfo.fileName">{{imageErrorInfo.fileName}}</div>
              <div class="error-text mt-2">
                <img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px">
                <span class="copy-message">{{imageErrorInfo.message}}</span>
              </div>
            </div>
          </div>
          <app-image-resizer [fetchedImg]="linkImgBase64" (imageError)="imageError($event)" (finalImage)="finalImage($event)"></app-image-resizer>
        </div>
        <div class="mt-3 pt-1">
          <label class="body-mini">
            Title
          </label>
          <input type="text" (input)="countWords()" [formControl]="linkForm.get('title')" [class.invalid]="linkForm.get('title').hasError('required') || linkForm.get('title').hasError('maxlength')" placeholder="Add Title">
          <div class="justify-content-between d-flex">
            <div class="error-text" *ngIf="linkForm.get('title').hasError('required') || linkForm.get('title').hasError('maxlength')">
              <img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px">
              <span *ngIf="linkForm.get('title').hasError('required')" class="copy-message">Title is required</span>
              <span *ngIf="linkForm.get('title').hasError('maxlength')" class="copy-message">Title can't be more than 100 characters</span>
            </div>
            <p class="body-mini" [class.ml-auto]="!linkForm.get('title').hasError('required')" >{{ wordCountTitle ? wordCountTitle : 0 }}/ 100</p>
          </div>

        </div>
        <div class="mt-3 pt-1">
          <label class="body-mini">
            Description
          </label>
          <textarea type="text" class="text-area" maxlength="750" (input)="countWords()" [formControl]="linkForm.get('description')" rows="4" [class.invalid]="linkForm.get('description').hasError('required')" placeholder="Add Description"></textarea>
          <div class="d-flex justify-content-between">
            <div class="error-text" *ngIf="linkForm.get('description').hasError('required')">
              <img src="../../../assets/images/new-icons/icons8-exclamation-100.png" height="16px" width="16px">
              <span class="copy-message">Description is required</span>
            </div>
            <p class="body-mini" [class.ml-auto]="!linkForm.get('description').hasError('required')">{{ wordCountDescription ? wordCountDescription : 0 }}/ 750</p>
          </div>
        </div>
        <div class="d-flex ml-auto mt-4 pt-1">
          <button class="btn tv-btn-light mr-3" (click)="modalLinkRef.hide()">Cancel</button>
          <button class="btn tv-btn-red ml-1" [disabled]="linkForm.invalid || invalidImage" (click)="addLinkPanel()">
            <div *ngIf="reload"
                class="loader-bar"></div>
           <div *ngIf="!reload">Add Link</div>
          </button>
        </div>
        <div class="error-text d-flex mt-2" *ngIf="errMessage">
          <img src="../../../assets/images/new-icons/icons8-exclamation-100.png" class="mt-1" height="16px" width="16px">
          <span class="copy-message">{{errMessage}}</span>
        </div>
      </ng-container>
    </div>
