import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PostActions from '../redux/action';
import { Post } from '../redux/model';
import { Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ComicService } from '../services/comic.service';

interface AppState {
  post: Post;
}

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss', '../comic/comic.component.scss']
})
export class TermsConditionComponent implements OnInit {
  currentDevice: string;
  isIpad: boolean;

  constructor(private store: Store<AppState>, @Inject(PLATFORM_ID) private platform: object, private meta: Meta, private comicService: ComicService) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }); var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (width == 1024) {
        this.isIpad = true;
      }
    }
    this.currentDevice = this.comicService.getOperatingSystem();
    // const metaData = [
    //   { property: 'twitter:description', content: 'Terms and Conditions' },
    //   {
    //     property: 'twitter:image',
    //     content: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg'
    //   },
    //   { property: 'twitter:title', content: 'Terms and Conditions' },
    //   { property: 'og:title', content: 'Terms and Conditions' },
    //   { property: 'og:description', content: 'Terms and Conditions' },
    //   {
    //     property: 'og:image',
    //     content: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg'
    //   },
    //   { property: 'og:url', content: 'https://tinyview.com/terms-conditions' },
    // ];
    // for (const metaProperty of metaData) {
    //   this.meta.updateTag({ property: metaProperty.property, content: metaProperty.content });
    // }
    // this.store.dispatch(new PostActions.EditText('Terms and Conditions'));
  }

}
