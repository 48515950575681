<div class="modal-cover">
	<!-- HEADER -->
	<img *ngIf="false" class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png"
		(click)="closeModal()" />
	<div *ngIf="false" class="heading">
		<h1></h1>
	</div>

	<!-- BODY -->
	<p>
		{{ body }}
	</p>

	<!-- BOTTOM BUTTONS -->
	<div class="modal-btn-container">
		<button class="btn tv-btn-light" (click)="action(btn1Action)">{{ btn1Text }}</button>
		<button class="btn tv-btn-red" (click)="action(btn2Action)">{{ btn2Text }}</button>
	</div>
</div>