<div class="fullscreen-section-content custom-container-margin">
  <div class="container align-content-center">
    <div class="content-width-small tv-card rm-box-shadow border-radius-10">
      <div class="panel-body">
        <div class="space-bottom-large">
          <h1>404 Error</h1>
          <h1 class="display-heading-4 text-dark">Page not found.</h1>
          <p>If you think this might be a mistake, <a (click)="onNavigate()">get in touch.</a></p>
        </div>
        <p>
          <a (click)="onNavigate()" class="btn tv-btn-red">Back to Home</a>
        </p>
      </div>
    </div>
  </div>
</div>