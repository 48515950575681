import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { flatMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { UploadInfo } from '../models/comic';
import { ComicService } from '../services/comic.service';
import { PanelService } from '../services/panel/panel.service';
import { StorageService } from '../services/storage/storage.service';
import { API_KEYS, latestComics } from '../constants/common.constants';
import { ApiService } from '../services/api.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {
  @Input() modalLinkRef: any;
  @Input() seriesName: string;
  @Input() fromComicPannel: boolean;
  @Output() getExternalLinkPanel: EventEmitter<any> = new EventEmitter();
  linkForm: any;
  linkUpload: UploadInfo = null;
  linkImgBase64: any;
  linkFetch = false;
  timestamp: number;
  invalidUrl: boolean;
  height: any;
  width: any;
  items: UploadInfo[] = [];
  fileError: string;
  currentUrl: string;
  localFile: File;
  reload: boolean = false;
  errMessage: string;
  imageErrorInfo: any;
  invalidImage: boolean;
  wordCountTitle: any;
  wordCountDescription: any;
  constructor( 
    private apiService: ApiService,
    private storageService: StorageService,
    public panelService: PanelService,
    private comicService: ComicService,
    private readonly fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.timestamp = (new Date()).getTime();
    this.linkForm = this.fb.group({
      url: [ null , Validators.required],
      title: [ null , [Validators.required, Validators.maxLength(100)]],
      description: [ null , Validators.required]
    });
  }

  async addLink() {
    this.errMessage = '';
    this.linkUpload = null;
    this.linkImgBase64 = undefined;
    const url: string = this.ensureHttps(this.linkForm.get('url').value);
    this.linkFetch = true;
    from(this.panelService.getScrapeURL(url, true))
      .pipe(
        flatMap((resp) => {
          return this.panelService.createExternalLinkPanel(url, resp.data.title, resp.data.description, resp.data.image, resp.data.author, resp.data.datetime, resp.data.imageBase64);
        })
      )
      .subscribe(
        async (resp) => {
          const formValues = {
            title: resp.title,
            description: resp.description
          }
          this.linkForm.patchValue(formValues);
          this.countWords();
          resp.datetime = resp.datetime ? new Date(resp.datetime).toISOString() : new Date().toISOString();
          this.linkUpload = {
            valid: true,
            timestamp: this.timestamp,
            panel: resp,
            url: resp.image ? resp.image : null,
            file: null,
            isExternal: true
          }
          this.linkImgBase64 = resp.imageBase64 ? resp.imageBase64 : 'no-image';
          this.invalidUrl = false;
        },
        (error) => {
          this.linkImgBase64 = 'no-image';
          this.linkForm.controls.url.setErrors({ urlFetchError: true })
          this.linkFetch = false;
          this.invalidUrl = true;
        }, () => { this.linkFetch = false }
      )
  }

  async addLinkPanel() {
    this.errMessage = '';
    this.reload = true;
    if (!this.linkForm.get('title').value) {
      this.linkUpload.panel.title = undefined;
    } else {
      this.linkUpload.panel.title = this.linkForm.get('title').value.trim();
    }
    if (!this.linkForm.get('description').value) {
      this.linkUpload.panel.description = undefined;
    } else {
      this.linkUpload.panel.description = this.linkForm.get('description').value;
    }
    this.linkUpload.panel.image = this.localFile.name;
    this.linkUpload.panel.template = 'toc';
    this.linkUpload.panel.width = this.width;
    this.linkUpload.panel.height = this.height;
    this.linkUpload.panel.comments = this.linkUpload.panel.description;
    this.linkUpload.isExternal = true;
    this.linkUpload.panel.series = this.currentUrl.replace('/', '');
    this.linkUpload.panel.image = this.getImgUrl(this.linkUpload.panel.image);
    if (this.validURL(this.linkUpload.panel.action)) {
      this.linkUpload.panel.domain = this.comicService.extractDomain(this.linkUpload.panel.action);
    }
    if (this.localFile) {
      await this.panelData(this.localFile);
    }
    if (this.fromComicPannel) {
      delete this.linkUpload.panel.imageBase64;
      this.linkUpload['url'] = this.linkUpload.panel.image;
      this.getExternalLinkPanel.emit(this.linkUpload);
      this.modalLinkRef.hide();
    } else {
      const storyData = [];
      let panelDetail: any = {};
      panelDetail = this.linkUpload.panel;
      delete panelDetail.imageBase64;
      storyData.push({ ...panelDetail });
      const storyFromComic = await this.apiService.send(API_KEYS.STORY_FROM_COMIC_WRITER);
      storyFromComic({
        storyData 
      }).then(() => {
        this.uploadComicInIndex(panelDetail);
        this.storageService.storeComics$.subscribe(res => {
          if (res) {
            this.reload = false;
            this.modalLinkRef.hide();
            location.reload();
          }
        })
      }).catch(err => {
        this.reload = false;
        this.errMessage = err.details.errorMsg;
        console.log(err.details);
      }
      )
    }
  }
  // https://dev.tinyview.com/tinyview/comic-series-directory
  public uploadComicInIndex(panelDetail) {
    // To insert the Published External Comic at the top of the series list
    this.comicService.getComicChapters(this.currentUrl, '').subscribe(async (resp) => {
      const doesComicExist = this.checkForExistingComic(resp, panelDetail);
      if (doesComicExist) {
        return;
      }
      resp.comics.panels.map((i, index) => {
        if (i.title == latestComics) {
          resp.comics.panels.splice(index + 1, 0, panelDetail);
        }
      });
      this.storageService.uploadIndex(this.currentUrl, resp.comics);
    });

    // To insert the Published External Comic at the top of the home page list
    this.comicService.getComicChapters('', '').subscribe(async (resp) => {
      const doesComicExist = this.checkForExistingComic(resp, panelDetail);
      if (doesComicExist) {
        return;
      }
      resp.comics.panels.map((i, index) => {
        if (i.title == latestComics) {
          panelDetail['user'] = this.comicService.userData(this.currentUrl + '/index.json');
          panelDetail.template = "story";
          resp.comics.panels.splice(index + 1, 0, panelDetail);
        }
      });
      this.storageService.uploadIndex('/', resp.comics);
    });
  }

  public checkForExistingComic(resp, panelDetail) {
    return resp.comics.panels.find(panel => panel.action === panelDetail.action)
  }

  public async panelData(file) {
    const seriesNameOfComic = this.seriesName ? this.seriesName : this.currentUrl;
    if (file) {
      await this.storageService.uploadPanelImageAndGetTask({ path: seriesNameOfComic, file: file, timestamp: file.lastModified });
    }
  }

  public getImgUrl(data: any) {
    const seriesNameOfComic = this.seriesName ? `/${this.seriesName}` : this.currentUrl;
    return this.panelService.getImgUrl(data, seriesNameOfComic);
  }

  public validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  ensureHttps(url: string): string {
    if (!url.match(/^(https?:\/\/)/i)) {
      return 'https://' + url;
    }
    return url;
  }

  imageError(event) {
    this.imageErrorInfo = event;
    if (this.imageErrorInfo.message !== '') {
      this.invalidImage = true;
    } else {
      this.invalidImage = false;
    }
  }

  finalImage(event) {
    this.localFile = event;
    this.panelService.getImageDimensions(this.localFile).subscribe(
      dimensions => {
        this.width = dimensions.width;
        this.height = dimensions.height;
      }
    );
  }

  countWords() {
    const textValueTitle = this.linkForm.get('title').value;
    const textValueDescription = this.linkForm.get('description').value;
    this.wordCountTitle = textValueTitle ? textValueTitle.length : 0;
    this.wordCountDescription = textValueDescription ? textValueDescription.length : 0;
  }
}
