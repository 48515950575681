export const apiMap = {
  // Add other function names and their corresponding API endpoints here
  'NOTIFICATION_SCHEDULER': { url: '/user/creator/notification', method: 'POST'},
  'EDIT_STORY': { url: '/story', method: 'PUT'},
  'DELETE_STORY': { url: '/story', method: 'DELETE'},
  'GET_DASHBOARD_DATA' : {url : '/dashboard/data', method: 'GET'},
  'GET_GIFTS_DASHBOARD_DATA_BY_DATE' : {url : '/dashboard/stats/gift/${date}', method: 'GET'},
  'GET_DASHBOARD_INVITE_DATA' : {url : '/dashboard/invite', method: 'GET'},
  'GET_REFFERER_DASHBOARD_DATA' : {url : '/dashboard/stats/referrer', method: 'GET'},
  'GET_CREATOR_DASHBOARD_DATA' : {url : '/dashboard/stats/creator', method: 'GET'},
  'GET_SERIES_CAROUSEL' : {url : '/story/carousel', method: 'GET'},
  'STORY_FROM_COMIC_WRITER': { url: '/story/create', method: 'POST' },
  'GET_MULTIPLE_PAGE_INFO': { url: '/story/pages', method: 'POST' },
  'CREATE_PORTAL_LINK' : {url : '/payment', method: 'POST'},
  'CREATE_CHECKOUT_LINK' : {url : '/payment/checkout', method: 'POST'},
  'GET_SUBSCRIPTION_METER_AGGREGATES' : {url : '/stats/subscriptions', method: 'GET'},
  'CANCEL_SUBSCRIPTION' : {url : '/payment/cancel-subscription', method: 'PUT'},
  'UPDATE_SUBSCRIPTION' : {url : '/payment/subscription', method: 'PUT'},
  'MERGE_AUTHENTICATED_USER': { url: '/user/merge', method: 'POST' },
  'SEND_TEXT_MESSAGE': { url: '/user/send-message', method: 'POST' },
  'UPDATE_USER_PROFILE': { url: '/user/profile', method: 'PUT' },
  'SCRAPE_URL' : {url : '/user/scrape', method: 'POST'},
  'UPDATE_PROFILE_PIC_URL': { url: '/user/profile/photo', method: 'POST' },
  'GET_UNLOCK_COMIC_URLS': { url: '/story/unlock-urls', method: 'GET' },
  'UPDATE_FRIEND_REQUEST': { url: '/user/friend-req', method: 'PUT' },
  'GET_FRIEND_REQUEST': { url: '/user/get-friend-req', method: 'POST' },
  'SEND_FRIEND_REQUEST': { url: '/user/friend-req', method: 'POST' },
  'GET_USER_PROFILE': { url: '/user/profile', method: 'GET' },
  'GET_ALL_GIFT_ITEM': { url: '/rewards/gift', method: 'GET' },
  'ADD_INFLUENCE_POINT': { url: '/rewards/redeem-points', method: 'POST' },
  'REDEEM_INFLUENCE_POINT': { url: '/rewards/redeem-points', method: 'PUT' },
  'GET_INFLUENCE_POINTS_FOR_ACTIONS': { url: '/rewards', method: 'GET' },
  'GET_ALL_COMICS': { url: '/story/comics', method: 'POST' },
  'GET_NOTIFICATIONS': { url: '/user/get-notifications', method: 'POST' },
  'GET_USER_FEED': { url: '/user/feed', method: 'POST' },
  'GET_SERIES_COMICS': { url: '/story/comics-list', method: 'POST' },
  'RECORD_STORY_VIEW': { url: '/stats/view', method: 'PUT' },
  'FETCH_COMMENTS': { url: '/story/comment', method: 'GET' },
  'ADD_COMMENT': { url: '/story/comment', method: 'POST' },
  'LIKE_COMMENT': { url: '/story/comment/like', method: 'POST' },
  'DISLIKE_COMMENT': { url: '/story/comment/dislike', method: 'POST' },
  'EDIT_COMMENT': { url: '/story/comment', method: 'PUT' },
  'DELETE_COMMENT': { url: '/story/comment', method: 'DELETE' },
  'GET_MY_REPOST': { url: '/story/get-repost', method: 'POST' },
  'ADD_REPOST': { url: '/story/repost', method: 'POST' },
  'SEND_POST': { url: '/story/post', method: 'POST' },
  'EDIT_REPOST': { url: '/story/repost', method: 'PUT' },
  'DELETE_REPOST': { url: '/story/repost', method: 'DELETE' },
  'DELETE_SENT_POST': { url: '/story/post', method: 'DELETE' },
  'GET_STORY_LIKES': { url: '/story/all-like', method: 'POST' },
  'FETCH_STORY_LIKES': { url: '/story/like/all-users', method: 'GET' },
  'LIKE_STORY': { url: '/story/like', method: 'POST' },
  'DISLIKE_STORY': { url: '/story/dislike', method: 'POST' },
  'GET_STORY_DETAILS': { url: '/story', method: 'GET' },
  'FETCH_COMMENT_LIKES': { url: '/story/comment/like', method: 'GET' },
  'FETCH_REPOSTED_USERS_LIST': { url: '/story/repost/fetch', method: 'POST' },
  'FETCH_WITH_WHOM_STORY_SHARED': { url: '/user/shared-with', method: 'GET' },
  'ADD_USER_DEVICE_TOKEN': { url: '/user/add-token', method: 'POST' },
  'SUBSCRIBE_TO_ALERT': { url: '/story/follow', method: 'POST' },
  'ADD_NEW_TRANSACTION': { url: '/user/transaction', method: 'POST' },
  'VALIDATE_TRANSACTION': { url: '/user/validate-transaction', method: 'POST' },
  'SET_REFERRER': { url: '/user/referrer', method: 'POST' },
  'RECORD_PAGEVIEW': { url: '/stats/page-view', method: 'POST' },
  'GET_PAGEVIEW_INFO': { url: '/story/info', method: 'GET' },
  'GET_MULTIPLE_PAGEVIEW_INFO': { url: '/story/pages', method: 'POST' },
  'GET_USER_DETAILS': { url: '/user', method: 'GET' },
  'UPDATE_USER_SETTINGS': { url: '/user/settings', method: 'PUT' },
  'SIGN_OUT_USER': { url: '/user/sign-out', method: 'POST' },
  'FLAG_COMMENT': { url: '/user/comment/flag', method: 'POST' },
  'BLOCK_USER': { url: '/user/block-comment', method: 'POST' },
  'PORTAL_LINK': { url: '/payment', method: 'POST' },
  'DELETE_USER_ACCOUNT': { url: '/user', method: 'DELETE' },
  'GET_SUBSCRIBERS_PROGRESS': { url: '/stats/subscriptions', method: 'GET' },
  'GET_NAVIGATION': { url: '/story/navigate', method: 'GET' },
  'REMOVE_PROFILE_IMAGE': { url: '/user/profile/photo', method: 'DELETE' },
  'UPDATE_LAST_SEEN_NOTIFICATION_AT': { url: '/user/notifications', method: 'PUT' },
  'ADD_EMAIL_FLOW_DATA': { url: '/user/add-email', method: 'POST' },
  'SET_NOTIFICATION_SETTINGS': { url: '/user/notifications', method: 'POST' },
  'HAS_COMIC_READ_ACCESS': { url: '/user/access/{pageUrl}', method: 'GET' },
  'UNFRIEND': { url: '/user/friends/{friendUID}', method: 'DELETE' },
  'GET_MY_FRIENDS': { url: '/user/friends/{userID}', method: 'GET' },
  'GET_FRIENDSHIP_STATUS': { url: '/user/friend/{userID}', method: 'GET' },
  'GET_EPISODES': { url: '/story/series-episode', method: 'POST' },
  'GET_EMAIL_FLOW_DATA': { url: '/user/data/{flowDataID}', method: 'GET' },
  'GET_APP_CONFIG': { url: '/user/app-config', method: 'GET' }
};