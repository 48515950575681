import { Injectable } from '@angular/core';

interface Options {
  currency: string,
  value: number
}

@Injectable({
  providedIn: 'root'
})
export class FbqService {

  constructor() { }

  public trackEvent(eventName: string, options?: Options) {
    try {
      console.log(`Tracking fbq event: ${eventName}`, options);
      options && (window as any).fbq('track', eventName, options);
      !options && (window as any).fbq('track', eventName);
      console.log(`Tracked fbq event: ${eventName}`, options);
    } catch (error) {
      console.log('Error while tracking fbq event: ', error);
    }
  }
}
