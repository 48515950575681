<div class="section-container">
  <div class="tv-columns">
    <div class="comic-desc-container">
      <!-- OPEN IN APP -->
      <!-- <div class="open-in-app justify-content-between pl-4 pr-3 py-2" *ngIf="(currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser') && isOpenApp">
        <div class="my-auto">
          <img alt="icon" class="tv-icon mr-2" src="../../assets/images/tinyview-logo-only-mark-square-rounded.png">
          <span class="body-mini">Comics delivered daily</span>
        </div>
        <div class="ml-3 ml-sm-5 text-nowrap my-auto">
          <button class="body-mini" (click)="openApp()">
            Open App
          </button>
          <a (click)="closeOpenAppCard()" class="toast-text-png ml-3"><img src="../../assets/images/new-icons/icons8-delete-100.png" alt="x" width="12px" height="12px"></a>
        </div>
      </div> -->
      <img [src]="INFLUENCE_PAGE.previewImg">

      <div class="tv-card pt-4">
        <!-- SERIES IMAGE -->
        <img class="series-profile cursor-pointer" [src]="INFLUENCE_PAGE.profilePic" [routerLink]="['/tinyview']" alt="Loading...">
        <!-- TITLE -->
        <div class="d-flex justify-content-between mr-3">
          <h1>
            {{INFLUENCE_PAGE.title}}
          </h1>
        </div>

        <div class="mb-3">
          <div class="d-flex mb-2">
            <span class="p-bold">{{ userDetails?.influencePoints?.total || 0 }}&nbsp;</span>
            <p>Influence</p>
          </div>
          <div class="d-flex">
            <span class="p-bold">{{ userDetails?.influencePoints?.balance || 0 }}&nbsp;</span>
            <p>Current balance</p>
          </div>
        </div>

        <h2 class="mt-3">
          <!-- <img src="../../assets/images/new-icons/icons8-quote-50.png" class="quotes" alt="Quotes"> -->
          <p class="comments" [innerHTML]="INFLUENCE_PAGE.description"></p>
        </h2>
      </div>
    </div>

    <div class="comic-panel-container px-2">
      <div class="influence-panel-img">
        <img alt="influence-panel-img"
          src="{{INFLUENCE_PAGE.image2}}">
      </div>

      <h1 class="m-20">
        How to Earn
      </h1>

      <ng-container *ngFor="let card of influenceCards">
        <app-influence-card *ngIf="card?.show" [cardData]="card" 
        [storyAction]="storyAction" [storyID]="storyID" [isWebsite]="isWebsite">
        </app-influence-card>
      </ng-container>

      <h1 class="m-20 mt-5">
        How to Use
      </h1>

      <div class="mb-5 pb-4">
        <ng-container *ngFor="let benefit of influenceBenefits">
          <div *ngIf="benefit.show" class="benefit-item tv-card">
            <img class="benefit-img" [src]="benefit.image">
            <div class="text-container">
              <div class="p-bold pt-1">{{benefit.title}}</div>
              <p class="mt-2">{{benefit.description}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>