import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags'
})
export class StripTagsPipe implements PipeTransform {

  transform(text: string, ...usefulTags: any[]): string {
    if (!text) {
      return text;
    }
      let str = usefulTags.length > 0
        ? text.replace(new RegExp(`<(?!\/?(${usefulTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
        : text.replace(/<(?:.|\s)*?>/g, '');
      if (str.match(/<img/)) {
        return str.replace(/<img .*?>/g, '');
      }
      return str;
    }

}
