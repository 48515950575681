import { Injectable } from "@angular/core";
import * as firebase from 'firebase/app';
import { ApiService } from "./api.service";
import { API_KEYS } from "../constants/common.constants";


@Injectable({
  providedIn: "root",
})

export class FriendService {
  constructor(
    private apiService: ApiService
  ) { }
  public async getFriendsByUserID(userID: string) {
    const getUserFriends = await this.apiService.send(API_KEYS.GET_MY_FRIENDS);
    const res = await getUserFriends({
        userID
    });
    return res.data;
  }

  public async removeFriendByFriendID(friendUID: string) {
    const removeFriend = await this.apiService.send(API_KEYS.UNFRIEND);
    const res = await removeFriend({
        friendUID
    });
    return res.data;
  }

  public async getFriendRequestsByType(input: any) {
    const getFriendRequests = await this.apiService.send(API_KEYS.GET_FRIEND_REQUEST);
    const res = await getFriendRequests({
        requestType : input.requestType,
        phoneNumber : input.phoneNumber,
        status: input.status
    });
    return res.data;
  }

  public async updateFriendRequestByReqID(data) {
    const updateFriendRequest = await this.apiService.send(API_KEYS.UPDATE_FRIEND_REQUEST);
    const res = await updateFriendRequest({
        ...data
    });
    return res.data;
  }
}