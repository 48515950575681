<div class="tv-card custom-margin">
  <h1 class="mb-4" [class.text-center]="isSignUpSuccess">{{!isSignUpSuccess ? title.signup : title.signupSuccess}}</h1>
  <div *ngIf="!isSignUpSuccess">
    <div class="d-flex justify-content-center mt-1 mb-4 pb-3 body-mini">
      <div [ngClass]="activeStep.one ? 'circle-filled' : 'circle-unfilled'">1</div>
      <div class="line" [class.red-bg]="activeStep.two"></div>
      <div [ngClass]="activeStep.two ? 'circle-filled' : 'circle-unfilled'">2</div>
      <div class="line" [class.red-bg]="activeStep.three"></div>
      <div [ngClass]="activeStep.three ? 'circle-filled' : 'circle-unfilled'">3</div>
  </div>
  <!-- <div id="send-otp"></div> -->
  <router-outlet></router-outlet>
  </div>
  <div *ngIf="isSignUpSuccess" class="text-center pb-3">
    <img [src]="confirmationPanel" alt="Confirmation Image">
    <button [routerLink]="['/']">Visit Home Page</button>
  </div>
</div>
