import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { ToastrService } from "ngx-toastr";
import { AUTH_TYPE, LOGIN_FLOW } from "src/app/constants/common.constants";
import { subscriptionAnonymousMsg } from "src/app/constants/subscription.constants";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import { FirebaseService } from "src/app/services/firebase.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LoginService } from "src/app/services/login.service";
import { WindowService } from "src/app/services/window.service";
import { isAnonymousUser } from "src/app/utilities/common.util";

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss', './../../signin/signin.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  isAnonymousUser: boolean;
  public phoneForm: FormGroup;
  phoneNumCodeErr: any;
  isNewSignUp: boolean;
  phoneFormNumber: string;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  SearchCountryField = SearchCountryField;
  maxPhoneLength = 20;
  exceptionCountries = ['US', 'IN'];
  fetchDone = true;
  isNewSignIn: boolean;
  PhoneNumberFormat = PhoneNumberFormat;
  currentFlow: string;
  isoCountryCode: string;
  message: string;
  comicSeries: string;
  captchaVerifier: any;
  isSubscriber: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private comicService: ComicService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private toastr: ToastrService,
    private fbService: FirebaseService,
    public win: WindowService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.isAnonymousUser = isAnonymousUser();
    this.createPhoneForm();
    this.currentFlow = this.loginService.getCurrentFlow(this.comicService.getCurrentUrl());
    this.message = this.route.snapshot.queryParams.message;
    this.comicSeries = this.route.snapshot.queryParams.comic;
    // Replacing subscriptions
    this.setPhoneNumFromQuery();
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
  }

  setPhoneNumFromQuery() {
    if (
      this.route.snapshot.queryParams.isoCountryCode
      && this.route.snapshot.queryParams.phoneNumber
    ) {
      this.isoCountryCode = this.route.snapshot.queryParams.isoCountryCode;
      this.phoneForm.get("phone").setValue(this.route.snapshot.queryParams.phoneNumber);
    }
  }

  private createPhoneForm(phone = '') {
    this.phoneForm = this.fb.group({
      phone: [phone, [Validators.required]]
    });
  }

  public clearMsgVars() {
    this.phoneNumCodeErr = '';
    this.isNewSignIn = false;
    this.isNewSignUp = false;
  }

  public cleanPhoneNumber(number) {
    if (!number) {
      return;
    }
    return number.includes(' ') ? (number.split(' ').join('')) : (number.includes('-') ? number.split('-').join('') : number);
  }

  public async phoneLogIn() {
    try {
      if (!this.captchaVerifier) {
        this.captchaVerifier = this.fbService.renderRecaptcha('send-otp');
      }
      const confirmationResult = await this.authService.signInPhone(this.phoneFormNumber, this.captchaVerifier);
      this.win.windowRef.confirmationResult = confirmationResult;
      this.navigateToOTP();
    } catch (error) {
      this.fetchDone = true
      this.phoneNumCodeErr = error;
    }
  }

  navigateToOTP() {
    let queryParams = this.comicSeries ? { message: this.message, comic: this.comicSeries }: {} 
    if (this.phoneFormNumber) { 
      queryParams['isoCountryCode'] = this.phoneForm.value.phone.countryCode;
      queryParams['phoneNumber'] = this.phoneForm.value.phone.number.replace(/\s+/g, '');
      // Phone Number Inclusive of county code [Required only on OTP screen]
      queryParams['phone164Number'] = this.phoneForm.value && this.phoneForm.value.phone && this.phoneForm.value.phone.e164Number;
    }
    this.loginService.navigateTo('phone/otp', this.currentFlow, queryParams);
  }

  public async logIn(phoneForm: FormGroup) {
    this.clearMsgVars();
    // Checking if phone number is valid or not
    if (phoneForm) {
      this.phoneFormNumber = phoneForm.value && phoneForm.value.phone && phoneForm.value.phone.e164Number;
      const phoneNumber = phoneForm.value.phone;
      if (phoneNumber && phoneNumber.number && this.exceptionCountries.includes(phoneNumber.countryCode)) {
        const filteredNumber = this.cleanPhoneNumber(phoneNumber.number);
        if (filteredNumber.length < 10) {
          this.phoneNumCodeErr = 'Invalid Phone Number.';
          return;
        }
      }
    }
    this.fetchDone = false;
    // Check if any user exists with phone number
    const userExists = await this.authService.checkForExistingUser(AUTH_TYPE.PHONE, this.phoneFormNumber);
    const IS_SIGNIN_FLOW = (this.currentFlow.toLowerCase() === LOGIN_FLOW.SIGNIN.toLowerCase() && userExists);
    const IS_SIGNUP_FLOW = (this.currentFlow.toLowerCase() === LOGIN_FLOW.SIGNUP.toLowerCase() && !userExists);
    const IS_ALERT_FLOW = ((this.currentFlow.toLowerCase() === LOGIN_FLOW.ALERTS.toLowerCase()) && (this.isAnonymousUser || !userExists));
    if ( IS_SIGNIN_FLOW || IS_SIGNUP_FLOW || IS_ALERT_FLOW ) {
      return this.phoneLogIn();
    } else if (userExists && [LOGIN_FLOW.SIGNUP].includes(this.currentFlow)) {
      this.phoneNumCodeErr = this.isNewSignIn = this.currentFlow === LOGIN_FLOW.SIGNUP ? true : false;
    } else if (!userExists && this.currentFlow === LOGIN_FLOW.SIGNIN) {
      this.phoneNumCodeErr = this.isNewSignUp = this.currentFlow === LOGIN_FLOW.SIGNUP ? false : true;
    } else if ((this.currentFlow.toLowerCase() === LOGIN_FLOW.ALERTS.toLowerCase()) && !this.isAnonymousUser && userExists) {
      this.phoneNumCodeErr = 'This phone number already exists.';
    }
    if (this.phoneNumCodeErr) {
      this.fetchDone = true;
      return;
    }
  }

  // This is used to handle enter event on input box of phone number
  phoneKeytab(e) {
    this.clearMsgVars();

    if (this.phoneForm.get('phone').valid) {
      if (e.keyCode === 13) {
        this.logIn(this.phoneForm);
      }
    }
  }

  public onPhoneNumberPaste() {
    setTimeout(() => {
      if (this.phoneForm.value && this.phoneForm.value.phone && this.phoneForm.value.phone.number) {
        const phoneNumber = this.phoneForm.value.phone.number;
        const cleanedInput = phoneNumber.replace(/[^a-zA-Z0-9 -]/g, '');
        this.phoneForm.patchValue({
          'phone': cleanedInput
        });
      }
    }, 10);
  }

  navigateTo() {
    const queryParams = this.comicSeries ? { message: this.message, comic: this.comicSeries }: {};
    if (this.route.snapshot.queryParams.showSubscriptionMsg) {
      queryParams['showSubscriptionMsg'] = subscriptionAnonymousMsg;
    }
    this.loginService.navigateTo('email', this.currentFlow, queryParams);
  }

  public navigateConditionally() {
    let goToFlow = '';
    let url = ''
    if (this.currentFlow === LOGIN_FLOW.SIGNUP) {
      goToFlow = LOGIN_FLOW.SIGNIN; // if sign up flow, then we will send user to sign in flow
      url = '/phone/number';
    } else if (this.currentFlow === LOGIN_FLOW.SIGNIN) {
      goToFlow = LOGIN_FLOW.SIGNUP;
      url = 'dob';
    }
    const queryParams = this.comicSeries ? { message: this.message, comic: this.comicSeries }: {};
    if (this.phoneFormNumber) {
      queryParams['isoCountryCode'] = this.phoneForm.value.phone.countryCode;
      queryParams['phoneNumber'] = this.phoneForm.value.phone.number.replace(/\s+/g, '');
    }
    this.loginService.navigateTo(url, goToFlow, queryParams );
  }
}
