<ng-container *ngIf="showString">
  <p class="date-spacing">{{showString}}</p>
</ng-container>
<div class="p-0" *ngIf="comicData?.comments || comicData?.comment || comicData?.image" [ngClass]="{'tv-card': !storyPage, 'pb-3': !storyPage && comicData.commentCount, 'pb-1': !storyPage && !comicData.commentCount}">
  <div>
    <div>
      <div class="panel-wrapper">
        <div class="middle-section" *ngIf="comicData?.image">
          <a *ngIf="comicData?.image && !isWebsite()" (click)="getImageAction()" style="text-decoration: none;">
            <img [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage, 'fade-image': comicData.isRead}" [ngStyle]="getImageBorder()" alt="panel image" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
            <div class="mx-2 px-1 mt-2" *ngIf="!storyPage && comicData.isRatingDataReady">
              <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
            </div>
            <!-- <div class="story-bottom">
              <span> {{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br />
              <span class="story-title">{{comicData?.title}}</span><br />
            </div> -->
          </a>

          <a (click)="getViewsForExternalLink()" *ngIf="isWebsite()" [href]="comicAction" style="text-decoration: none;"
            target="_blank">
            <img [src]="getImgUrl(comicData)" [ngClass]="{'story-img': !storyPage, 'fade-image': comicData.isRead}" [ngStyle]="getImageBorder()" alt="panel image" onError="this.src='../../assets/images/new-icons/preview-image-not-available.jpg'">
            <div class="mx-2 px-1 mt-2" *ngIf="!storyPage && comicData.isRatingDataReady">
              <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
            </div>
            <!-- <div class="story-bottom">
              <span> {{comicData?.domain ? comicData?.domain : 'tinyview.com' }}</span><br />
              <span class="story-title">{{comicData?.title}}</span><br />
            </div> -->
          </a>
          <div class="top-section">
            <div class="mr-3" [ngClass]="{ 'mt-2' : storyPage, 'd-flex' : !storyPage }">
              <a *ngIf="comicData?.user?.image && !storyPage" (click)="onImageClick()" class="pt-3">
                <div class="user-img-wrapper">
                  <img class="user-img" [src]="getImgUrl(comicData.user)" alt="user's image"
                  onError="this.src='../../assets/images/male-user-100.png'">
                </div>
              </a>
              <a *ngIf="(comicData && comicData.user && !comicData?.user.image) && comicData.user.name && !storyPage" (click)="onImageClick()" class="pt-3">
                <div class="user-img-wrapper2" [class.ml-3]="!storyPage && comicData.refType === 'SENT'">
                  <p>{{getFirstLetters(comicData.user.name)}}</p>
                </div>
              </a>
              <div [class.user-name-wrapper]="!storyPage" *ngIf="comicData?.image" [ngClass]="{'mt-3': !storyPage, 'margin-top-25': !storyPage}">
                <div class="d-inline-flex" *ngIf="!storyPage">
                  <div>
                    <a *ngIf="comicData?.user?.name && !storyPage" (click)="onImageClick()">
                      <div class="user-name p-bold">{{comicData?.user.name}}
                        <span class="user-badges" *ngIf="comicData?.user?.badges && comicData?.user?.badges[0]">
                          <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comicData.user.badges[0])">
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="d-inline-flex" *ngIf="storyPage && comicData.user && comicData.user.name">
                  <h2 class="user-name ml-20 pl-0" [routerLink]="comicData.user.userId ? null : ['/'+ comicData.series]"
                    [class.cursor-pointer]="!comicData.user.userId">
                    {{comicData.user.name}}
                    <span class="user-badges" *ngIf="comicData?.user?.badges && comicData?.user?.badges[0]">
                      <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comicData.user.badges[0])">
                    </span>
                  </h2>
                </div>
                <!-- <span *ngIf="!isWebsite()">
                  <p class="story-comic-date body-mini" *ngIf="comicDate && isMobile && !storyPage">{{comicDate.split('•')[0]}}<br>{{comicDate.split('•')[1]}} </p>
                  <p class="story-comic-date" *ngIf="comicDate && !isMobile || storyPage">{{comicDate}}</p>
                </span> -->
                <a [routerLink]="['/'+ comicData.series]" *ngIf="storyPage && !isWebsite() && !(comicData.user && comicData.user.name)">
                  <h2 class="ml-2 pl-2">{{formatSeriesName(comicData.series)}}</h2>
                </a>
                <div class="mx-2 px-1 my-2" *ngIf="storyPage && comicData.isRatingDataReady">
                  <app-comic-rating [comic]="comicData" (showRead)="showRead($event)"></app-comic-rating>
                </div>
              </div>
              <!-- <button *ngIf="!storyPage && !refTypeCondition" class="btn tv-btn-light series-btn body-mini" (click)="onImageClick()">
                Series Home
              </button> -->
              <button *ngIf="!storyPage && !refTypeCondition" class="btn tv-btn-light series-btn body-mini d-flex align-items-center" (click)="onImageClick()" [ngClass]="{ 'mt-3':isHomePage }"> 
                <img alt="list" width="16px" height="16px" class="mr-2" src="../../assets/images/new-icons/icons8-list-96.png">
                Episodes 
              </button>
              <button *ngIf="storyPage && isWebsite() && !(comicData.user && comicData.user.name)" class="btn tv-btn-light series-btn body-mini story-series-btn ml-0" (click)="openLinkInNewTab(comicAction)">
                {{ externalButtonText }}
              </button>
            </div>
            <div class="d-flex justify-content-between align-items-start mr-3" [class.mb-2]="!comicData?.comments">
              <div class="w-100" [class.title-container]="isMobile && storyPage" [ngClass]="{'mt-12 pt-0': storyPage}">
                <a class="d-flex ml-20 justify-content-between" [class.mt-2]="!storyPage" (click)="isWebsite() ? getViewsForExternalLink() : null" [href]="comicAction" [target]="isWebsite() ? '_blank' : ''">
                  <div class="pt-1 p-bold mr-3" [ngClass]="{'pt-0': storyPage}">{{ comicData.title  | truncate:100 }}</div>
                  <img *ngIf="showReadTicks || (!comicData.isRatingDataReady && comicData.isRead)" class="mr-3 mt-1" alt="Read" height="20px"
                    src="../../assets/images/new-icons/icons8-double-tick-100.png" width="20px">
                </a>
                <span>
                    <!-- <p class="story-comic-date body-mini mt-1 ml-3" *ngIf="comicDate && isMobile && !storyPage">{{comicDate.split('•')[0]}}<br>{{comicDate.split('•')[1]}} </p> -->
                    <p class="body-mini mt-1 ml-20" *ngIf="comicDate">{{comicDate}}</p>
                </span>
              </div>
              <a *ngIf="isWebsite() && !storyPage" class="btn tv-btn-light ext-btn body-mini d-flex align-items-center justify-content-center" (click)="getViewsForExternalLink()" [href]="comicAction" target="_blank" [ngClass]="{ 'mt-3':isHomePage }"> 
                <img src="../../assets/images/new-icons/icons8-external-link-100.png" width="16px" height="16px" alt="External Link" class="mr-1">
                <span class="body-mini text-nowrap">{{externalButtonText}}</span>
              </a>
              <button *ngIf="storyPage && !isWebsite()" class="btn tv-btn-light series-btn body-mini mr-3 ml-0" (click)="getImageAction()">
                View Comic
              </button>
                <a *ngIf="storyPage && isWebsite() && comicData.user && comicData.user.name" class="btn tv-btn-light series-btn mt-2 body-mini mr-3" (click)="!isWebsite() ? getImageAction() : getViewsForExternalLink()" [href]="isWebsite() ? comicAction : null" [target]="isWebsite() ? '_blank' : ''">{{ externalButtonText }}</a>
            </div>

            <!-- <img *ngIf="(comicData?.comments || comicData?.comment)" src="../../assets/images/new-icons/icons8-quote-50.png" class="quotes" alt="Quotes"> -->
            <div (click)="getCommentAction()" class="comments cursor-pointer" *ngIf="(comicData?.comments || comicData?.comment) && !storyPage"
              [innerHTML]="(comicData?.comments || comicData?.comment) | stripTags: 'br' :'p' :'i' :'b'">
              {{comicData.comments}}
            </div>
          </div>
        </div>
        <app-action-bar *ngIf="comicData?.image && !storyPage" [comicAction]="comicData?.action" [storyData]="comicData" [record]="record"
          [isLike]="comicData?.isLiked" [commentCount]="comicData?.commentCount" [repostCount]="comicData?.repostCount"
          [viewCount]="comicData?.views" [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID"
          [giftItemCount]="comicData?.giftsCount" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
          [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
        </app-action-bar>
        <app-comic-buttons [comicData]="comicData"></app-comic-buttons>
      </div>
    </div>
    <app-action-bar *ngIf="comicData?.image && storyPage" [comicAction]="comicData?.action" [storyData]="comicData" [record]="record"
      [isLike]="comicData?.isLiked" [commentCount]="comicData?.commentCount" [repostCount]="comicData?.repostCount"
      [viewCount]="comicData?.views" [likeCount]="comicData?.likeCount" [storyID]="comicData?.storyID"
      [giftItemCount]="comicData?.giftsCount" [giftedItems]="comicData?.giftedItems" [actionType]="comicData?.actionType"
      [desc]="comicData?.comments || comicData?.comment" [img]="getImgUrl(comicData)" [title]="comicData?.title" [refType]="comicData.refType">
    </app-action-bar>
  </div>
</div>

