<div class="gift-block"  *ngIf="!commentId">
    <div class="container">
        <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="giftModalRef.hide()" />
        <div class="topnav">
            <ng-container *ngFor="let product of productData">
                <div *ngIf="product.count" [ngStyle]="(product.reactionName === selectedProduct) && { 'border-bottom': '1px solid red' }" class="sortType"
                    (click)="getDataFromTabs(product.reactionName, true)">
                  <img [src]="product.imageSrc"/>
                  <!-- [srcset]="generateSrcset(product.srcset)"  -->
                  <span class="gift-counts">{{product.count}}</span>
                </div>
            </ng-container>
        </div>
        <div class="search-results"
            (scrolled)="onScroll()">
            <div class="gift-loader-bar" *ngIf="showLoader"></div>
            <ng-container *ngFor="let list of giftsList">
                <div class="user-details">
                    <div>
                        <img *ngIf="list?.user?.image" [src]="list?.user?.image" class="user-image image-border"
                            onError="this.src='../../assets/images/male-user-100.png'" />
                        <div class="img-placeholder" *ngIf="!list?.user?.image">
                            <p>{{getFirstLetters(list.user.name)}}</p>
                        </div>
                    </div>
                    <div class="user-data">
                        <div>
                            <p class="user-name" >{{ list.user.name || 'Guest User' }}
                                <span *ngIf="list.user.userId == UID"> (You)</span>
                                <span class="user-badges">
                                    <ng-container *ngIf="list.user.badges && list.user.badges[0]">
                                        <img alt="badge-icon" class="badge-icon" 
                                        [src]="getBadgeURL(list.user.badges[0])"
                                        >
                                    </ng-container>
                                </span>
                            </p>
                            <p class="body-mini mb-2" [ngClass]="{'custom-margin': list?.user && list?.user?.badges && !list?.user?.badges.length}">
                                {{showTime(list.createdAt)}}
                            </p>
                        </div>
                        <div class="gifts">
                            <ng-container *ngFor="let reaction of productData">
                                <img *ngIf="list.reaction == reaction.reactionName"
                                    [src]="reaction.imageSrc"
                                    class="reaction-size"/>
                                    <!-- [srcset]="generateSrcset(reaction.srcset)" -->
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="gift-block"  *ngIf="commentId">
    <div class="container">
        <img class="cross-btn" src="../../assets/images/new-icons/icons8-delete-100.png" (click)="showUserNamesRef.hide()" /><br />
        <h2 class="text-center">Likes</h2>
        <div class="search-results"
            (scrolled)="onScroll()">
            <div class="gift-loader-bar" *ngIf="showLoader"></div>
            <ng-container *ngFor="let list of userCommentDetails">
                <div class="user-details">
                    <div>
                        <img *ngIf="list?.user?.image" [src]="list?.user?.image" class="user-image image-border"
                            onError="this.src='../../assets/images/male-user-100.png'"/>
                        <div class="img-placeholder" *ngIf="!list?.user?.image">
                            <p>{{getFirstLetters(list.user.name)}}</p>
                        </div>
                    </div>
                    <div class="user-data">
                        <div>
                            <p class="user-name">{{ list.user.name || 'Guest User' }}
                                <span *ngIf="list.user.userId == UID"> (You)</span>
                                <span class="user-badges">
                                    <ng-container *ngIf="list.user.badges && list.user.badges[0]">
                                        <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(list.user.badges[0])">
                                    </ng-container>
                                </span>
                            </p>
                            <p class="body-mini mb-2" [ngClass]="{'custom-margin': list.user.badges && list.user.badges[0]}">
                                {{showTime(list.createdAt)}}
                            </p>
                        </div>
                        <div class="d-flex justify-content-end col-3">
                            <img *ngIf="list.reaction == 'LIKE'" src="../../assets/images/red-heart.png"
                                width="25px" height="25px" />
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>