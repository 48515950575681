import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CreatorStatTableComponent } from "../components/creator-stat-table/creator-stat-table.component";
import { DashboardStatsComponent } from "../components/dashboard-stats/dashboard-stats.component";
import { PageViewRevenueComponent } from "../components/page-view-revenue/page-view-revenue.component";
import { SeriesFilterComponent } from "../components/series-filter/series-filter.component";
import { CreatorsDashboardRoutingModule } from "./creators-dashboard-routing.module";
import { CreatorsDashboardComponent } from "./creators-dashboard.component";
import { ReferralDashboardComponent } from "../components/referral-dashboard/referral-dashboard.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { SharedModuleModule } from "../shared-module/shared-module.module";
import { AppMaterialModule } from "../app-material.module";
import { HttpClientModule } from "@angular/common/http";
import { TopReferrerDashboardComponent } from "../components/top-referrer-dashboard/top-referrer-dashboard.component";
import { TotalsDashboardComponent } from "../components/totals-dashboard/totals-dashboard.component";

@NgModule({
  declarations: [
    CreatorsDashboardComponent,
    DashboardStatsComponent,
    PageViewRevenueComponent,
    CreatorStatTableComponent,
    SeriesFilterComponent,
    ReferralDashboardComponent,
    TopReferrerDashboardComponent,
    TotalsDashboardComponent,
    DashboardComponent
  ],
  imports: [
    CreatorsDashboardRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModuleModule,
    AppMaterialModule,
    HttpClientModule,
  ]
})
export class CreatorsDashboardModule { }