import { Injectable } from "@angular/core";
import { FirebaseService } from "./firebase.service";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { of } from "rxjs";
import { API_KEYS } from "../constants/common.constants";
import { ApiService } from "./api.service";

const COMIC_API = `${environment.API_END_POINT}`;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private readonly http: HttpClient,
    private firebaseService: FirebaseService,
    private cacheService: CacheService,
    private apiService: ApiService
  ) { }

  public async getAppConfig() {
    const cacheAppConfigData = this.cacheService.get('appConfigData');
    if (cacheAppConfigData) return JSON.parse(cacheAppConfigData);
    const getAppConfig = await this.apiService.send(API_KEYS.GET_APP_CONFIG);
    const res = await getAppConfig();
    const appConfigData = res.data.data;
    this.cacheService.set('appConfigData', `${JSON.stringify(appConfigData)}`, 86400000)
    return appConfigData
  }

  public getMaxVersionForApp() {
    const cacheLatestWebVersion = this.cacheService.get('latestWebVersion');
    if (cacheLatestWebVersion) return of(JSON.parse(cacheLatestWebVersion));
    const url = `${COMIC_API}/tinyview/update/versions.json?${Date.now()}`;
    return this.http.get<any>(url).pipe(
      map(result => {
        const latestWebVersion = result.latestAppInfo && result.latestAppInfo.versions[0]['webVersion'];
        this.cacheService.set('latestWebVersion', `${JSON.stringify(latestWebVersion*1)}`, 10800000) // 3 hours
        return latestWebVersion;
      })
    )
  }

  public async updateComicDataWithConfig(comicData) {
    const appConfigData = await this.getAppConfig();
    const appConfig = appConfigData.comicConfig;
    // Set default keys if they are not present in comicData
    const ageRatingKey = comicData["rating"] || "all-ages";
    const exclusivityKey = comicData["exclusivity"] || "non-exclusive";
    const freemiumKey = comicData["show-to"] || "everyone";
    // const freemiumKey = "premium";

    // Update comicData with corresponding values from appConfig
    const updatedComicData = {
      ...comicData,
      "rating": appConfig["rating"] && { ...appConfig["rating"][ageRatingKey], key: ageRatingKey } || null,
      "exclusivity": appConfig["exclusivity"] && { ...appConfig["exclusivity"][exclusivityKey], key: exclusivityKey } || null,
      "show-to": appConfig["show-to"] && { ...appConfig["show-to"][freemiumKey], key: freemiumKey } || null,
      // "isRead": true
    };
    if (updatedComicData.rating.key !== 'all-ages' || updatedComicData['show-to']['key'] !== 'everyone') updatedComicData["isRatingDataReady"] =  true
    return updatedComicData
  }

  public async getGifts() {
    const appConfigData = await this.getAppConfig();
    const appGifts = appConfigData.giftItems;
    return appGifts;
  }

  public async getSeriesCarousel(data) {
    const getSeriesCarousel = await this.apiService.send(API_KEYS.GET_SERIES_CAROUSEL); 
    const res = await getSeriesCarousel({
      type: data.type
    });
    const seriesWiseCarouselData = res.data.data;
    return seriesWiseCarouselData;
  }
}