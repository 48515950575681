import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(x: any): string {
    if (isNaN(x)) return x;
    // This logic is for both Likes and Views count only (Same as mobile app)
    if (x >= 10000) {
      // Nine Zeroes for Billions
      let absCountNumber = Math.abs(Number(x)) >= 1.0e+9
        ? (Math.abs(Number(x)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(x)) >= 1.0e+6
          ? (Math.abs(Number(x)) / 1.0e+6).toFixed(2) + "M"
          // Three Zeroes for Thousands
          : (Math.abs(Number(x)) / 1.0e+3).toFixed(1) + "K"

      absCountNumber = absCountNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return absCountNumber;
    }

    return x != undefined && x != null ? x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null
  }
}
