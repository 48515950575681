import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { from, Observable, Subject } from 'rxjs';
import { API_KEYS } from '../constants/common.constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StoryFeedService {
  private comicAction = new Subject<string>();

  constructor(
    private apiService: ApiService
  ) { }

  emitComicAction(data: string): void {
    this.comicAction.next(data);
  }

  loadComicAction(): Subject<string> {
    return this.comicAction;
  }

  getUserFeed(value: string): Observable<any> {
    return from(this.apiService.send(API_KEYS.GET_USER_FEED).then(userFeed =>
      userFeed({
        "startAfter": value,
        "records": 10
      })
    ));
  }

  async getStory(action: string, image?: string, storyMeta?) {
    const getStory = await this.apiService.send(API_KEYS.GET_STORY_DETAILS);
    const reqData = {
      "action": action
    }
    if (image) reqData['image'] = image;
    if (storyMeta) {
      reqData['url'] = storyMeta.url;
      reqData['title'] = storyMeta.title;
      reqData['description'] = storyMeta.description;
      reqData['series'] = storyMeta.series;
    }
    return getStory(reqData);
  }

  async getSeriesPageFeeds(value: any, series: any, count, onlyUnread = false) {
    const seriesPageFeeds = await this.apiService.send(API_KEYS.GET_SERIES_COMICS)
    return seriesPageFeeds({
      "series": series.replace('/', ''),
      "startAfter": value,
      "records": count,
      "onlyUnread": onlyUnread
    })
  }

  async doLikeStory(id: any) {
    const getLikes = await this.apiService.send(API_KEYS.LIKE_STORY);
    return getLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async doDislikeStory(id: any) {
    const getDisLikes = await this.apiService.send(API_KEYS.DISLIKE_STORY);
    return getDisLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async getAllStoryLikes(ids: any) {
    const getAllLikes = await this.apiService.send(API_KEYS.GET_STORY_LIKES);
    return getAllLikes({
      "storyIDs": ids
    })
  }


  getAllComics(ids: any): Observable<any> {
    return from(this.apiService.send(API_KEYS.GET_ALL_COMICS).then(getAllLikes =>
      getAllLikes({
        "startAfter": ids,
        "records": "20",
        "likeInfo": 1
      })
    ));
  }

  public async editStory(editedPanels: any) {
    const editStory = await this.apiService.send(API_KEYS.EDIT_STORY);
    return editStory(editedPanels)
  }

  public async deleteStory(deletedPanels: any) {
    const deleteStory = await this.apiService.send(API_KEYS.DELETE_STORY);
    return deleteStory(deletedPanels)
  }

  public async getFreinds() {
    const getFreinds = await this.apiService.send(API_KEYS.GET_MY_FRIENDS);
    return getFreinds()
  }

  public async getComments(storyId: string, records: number, commentId: string, order = 'asc') {
    const getComment = await this.apiService.send(API_KEYS.FETCH_COMMENTS);
    return getComment({
      "storyID": storyId,
      "records": records,
      "startAfter": commentId,
      "sortingOrder": order
    });
  }

  async doLikeComment(storyID: string, commentID: string, subCommentID: string) {
    const likeComment = await this.apiService.send(API_KEYS.LIKE_COMMENT);
    return likeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async userLikedComments(commentID: string, subCommentID: string) {
    const fetchCommentLikes = await this.apiService.send(API_KEYS.FETCH_COMMENT_LIKES);
    return fetchCommentLikes({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "records": 100,
      "startAfter": ""
    })
  }

  async doDislikeComment(storyID: string, commentID: string, subCommentID: string) {
    const disLikeComment = await this.apiService.send(API_KEYS.DISLIKE_COMMENT);
    return disLikeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async addComments(storyID: string, commentText: string, commentID: string) {
    const addComments = await this.apiService.send(API_KEYS.ADD_COMMENT);
    return addComments({
      "storyID": storyID,
      "commentText": commentText,
      "commentID": commentID
    })
  }

  async deleteComment(storyID: string, commentID: string, subCommentID: string) {
    const deleteComment = await this.apiService.send(API_KEYS.DELETE_COMMENT);
    return deleteComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async editComment(storyID: string, commentID: string, subCommentID: string, commentText: string) {
    const editComment = await this.apiService.send(API_KEYS.EDIT_COMMENT);
    return editComment({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "storyID": storyID,
      "commentText": commentText
    })
  }

  async getStoryByID(storyID: string) {
    const getStoryByID = await this.apiService.send(API_KEYS.GET_STORY_DETAILS);
    return getStoryByID({
      "storyID": storyID
    })
  }

  async getStoryLikes(storyID: string, startAfterID: string, reactionType: string) {
    const getStoryLikes = await this.apiService.send(API_KEYS.FETCH_STORY_LIKES);
    return getStoryLikes({
      "storyID": storyID,
      "records": 100,
      "startAfter": startAfterID,
      "reactionType": reactionType
    })
  }
}
