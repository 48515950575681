import { Component, OnInit } from '@angular/core';
import { ComicService } from '../services/comic.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  userObj: { bio: any; gender: any; link: any; name: any; userImage: any; phoneNumber: any; };
  userDetailsLoading: boolean;

  constructor(
    private readonly comicService: ComicService
  ) { }

  ngOnInit() {
    this.userDetailsLoading = true;
    this.comicService.$userDetails.subscribe((val) => {
      this.userObj = {
        bio: val && val.data && val.data.aboutMe,
        gender: val && val.data && val.data.gender,
        link: val && val.data && val.data.socialLinks && val.data.socialLinks[3],
        name: val && val.data && val.data.displayName,
        userImage: val && val.data && val.data.photoURL,
        phoneNumber: val && val.data && val.data.phoneNumber
      }
      this.userDetailsLoading = false;
    }, () => {
      this.userDetailsLoading = false;
    });
  }

}
