<!-- Follow Series Banner-->
<app-top-banner [isTopBanner]="true" [series]="series" [followBtnText]="followBtnText" (onPropertyChange)="onBannerChange($event)" [showTo]="null"></app-top-banner>
<!-- If no notification then we need to render this component -->
<div class="mb-60" *ngIf="!notifications.length && !isLoadingNotification">
  <app-new-user [notificationColumn]="true"></app-new-user>
</div>

<!-- Show notifications if there are any -->
<div class="notification-main" *ngIf="isLoadingNotification">
  <img [src]="loadingGif" class="gif" alt="Loading Notifications">
</div>
<div class="notification-main" *ngIf="notifications.length">
  <section class="mx-3 pt-4 mb-2" [class.mb-4]="!isMobile">
    <h1>Notifications</h1>
    <p class="mt-3">Notifications from creators and friends you follow</p>
  </section>

  <div *ngIf="categorizedNotifications && !isLoadingNotification">
    <section *ngIf="categorizedNotifications.today.length !== 0" [class.pb-58]="categorizedNotifications.yesterday.length === 0 && categorizedNotifications.earlier.length === 0">
      <p class="ml-3" [ngClass]="{'my-3 pb-1 pt-3': !isMobile, 'mb-2 mt-2': isMobile}">Today</p>
      <a (click)="navigateTo(item.navigationURI)" class="row m-0 p-3 notficaion-item" [class.unread]="isUnread(item.createdAt)"
        *ngFor="let item of categorizedNotifications.today">
        <div class="col-2 text-right">
          <img *ngIf="item.user.image" [src]="item.user.image || noImageUser" alt="User Image" (error)="handleImageError($event)" class="images image-border">
          <div class="img-placeholder" *ngIf="!item.user.image">
            <p>{{getFirstLetters(item.user.name)}}</p>
          </div>
        </div>
        <div class="col-10 text-left">
          <div class="row justify-content-between">
            <span class="p-bold col-8">{{item.title}}</span>
            <p class="col-4 text-right">{{item.duration}}</p>
          </div>
          <p class="row notification-body" [innerHTML]="(item.body || '') | stripTags: 'br' :'p' :'i' :'b'">{{item.body}}</p>
        </div>
      </a>
    </section>
    <section *ngIf="categorizedNotifications.yesterday.length !== 0" [class.pb-58]="categorizedNotifications.earlier.length === 0">
      <p class="ml-3" [ngClass]="{'mt-5 mb-3 pb-1': !isMobile, 'mt-3 pt-2 mb-2': isMobile}">Yesterday</p>
      <a (click)="navigateTo(item.navigationURI)" class="row m-0 p-3 mb-3 notficaion-item border-bottom-grey" 
        [ngClass]="{'unread': isUnread(item.createdAt), 'mb-3 border-bottom-grey': !isUnread(item.createdAt)}"
        *ngFor="let item of categorizedNotifications.yesterday">
        <div class="col-2 text-right">
          <img *ngIf="item.user.image" [src]="item.user.image || noImageUser" alt="User Image" (error)="handleImageError($event)" class="images image-border">
          <div class="img-placeholder" *ngIf="!item.user.image">
            <p>{{getFirstLetters(item.user.name)}}</p>
          </div>
        </div>
        <div class="col-10 text-left">
          <div class="row justify-content-between">
            <span class="p-bold col-8">{{item.title}}</span>
            <p class="col-4 text-right">{{item.createdAt | date:'h:mm a'}}</p>
          </div>
          <p class="row notification-body" [innerHTML]="(item.body || '') | stripTags: 'br' :'p' :'i' :'b'">{{item.body}}</p>
        </div>
      </a>
    </section>
    <section *ngIf="categorizedNotifications.earlier.length !== 0" class="pb-58">
      <p class="ml-3" [ngClass]="{'mt-5 mb-3 pb-1': !isMobile, 'mt-3 mb-2 pt-2': isMobile}">Earlier</p>
      <a *ngFor="let item of categorizedNotifications.earlier" (click)="navigateTo(item.navigationURI)" 
        class="m-0 row p-3 notficaion-item mb-3 border-bottom-grey"
        [ngClass]="{'unread': isUnread(item.createdAt), 'mb-3 border-bottom-grey': !isUnread(item.createdAt)}">
        <div class="col-2 text-right">
          <img *ngIf="item.user.image" [src]="item.user.image || noImageUser" (error)="handleImageError($event)" alt="User Image" class="images image-border">
          <div class="img-placeholder" *ngIf="!item.user.image">
            <p>{{getFirstLetters(item.user.name)}}</p>
          </div>
        </div>
        <div class="col-10 text-left">
          <div class="row justify-content-between">
            <span class="p-bold col-8">{{item.title}}</span>
            <p class="col-4 text-right">{{item.duration}}</p>
          </div>
          <p class="row notification-body" [innerHTML]="(item.body || '') | stripTags: 'br' :'p' :'i' :'b'" >{{item.body}}</p>
        </div>
      </a>
    </section>
  </div>
  <!-- <div *ngIf="notifications.length === 0 && !isLoadingNotification" class="text-center mx-3">
    <img [src]="noNotificationImage" alt="No Notification Icon" class="no-notification my-4 mx-0">
    <h2 class="pt-3">No notifications yet!</h2>
    <p>Follow your favorite creators or invite friends to get updates from them.</p>
    <p class="d-flex justify-content-center mx-5 mt-4">
      <button class="btn tv-btn-red" [routerLink]="['/tinyview/comic-series-directory']">All Series</button>
      <button class="btn tv-btn-red ml-3" (click)="openInviteFriendsModal(inviteFriendsModal)">Invite Friends</button>
    </p>
  </div> -->
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="false" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>