
<div class="tv-card payment-container overflow-hidden" [ngClass]="{'p-0': data.image, 'mt-0': (customStyles && customStyles['mt-0']) || isSubscribePage}">
  <img *ngIf="data.image" [src]="getImgUrl(data)" alt="card-img" (click)="redirectToUrl(data.action)" [ngClass]="{'cursor-pointer': data.action}">
  <div class="inner-block" [ngClass]="{'p-20': data.image && (data.title || data.icon || data.button || data.description)}">
    <img *ngIf="data.icon" class="icon-32" [src]="data.icon" alt="card-inner-img">
    <div class="desc-block ml-3" [ngClass]="{'ml-3': data.icon}">
      <h2 *ngIf="data.title">{{data.title}}</h2>
      <p *ngIf="data.description" [ngClass]="{'lh-2': applyLH(), 'mt-2': customStyles?.descriptionLH == 'normal', 'mb-3': customStyles?.descriptionLH == 'normal' && data.button}" [innerHTML]="data.description"></p>
      <div class="subc-block" *ngIf="data.button" [ngClass]="{'mt-2': data.description || data.title || data.image}">
        <button (click)="redirectToUrl(data.action)" class="btn tv-btn-red">
          <p class="text-white">{{data.button}}</p>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="false" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>
