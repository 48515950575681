<div style="margin: 15px 0px">
  <ng-container *ngIf="comicData?.template == 'episodes'">
    <h1 class="ml-3 pt-2 mb-0" *ngIf="comicData?.title"> {{comicData.title}} </h1>
    <div class="description" *ngIf="comicData?.description" [innerHTML]="comicData.description"></div>
    <div class="tv-card px-0 card-border">
      <div class="text-center" *ngIf="top">
        <a (click)="navigateOnClick(top)">
          <img [src]="top.image" (error)="onImageError(top)" class="episode-img top" />
          <!-- <img src="../../assets/images/new-icons/icons8-collapse-arrow-50-2.png" class="arrow arrow-top" alt="top"> -->
        </a>
      </div>
      <div class="row justify-content-center">
        <div *ngIf="previous" (click)="navigateOnClick(previous)">
          <div>
            <!-- <img src="../../assets/images/new-icons/icons8-back-50.png" class="arrow arrow-left" alt="left"> -->
            <img [src]="previous.image" (error)="onImageError(previous)" class="episode-img left" />
          </div>
        </div>
        <div *ngIf="currentEpisodeImage">
          <div>
            <img [src]="currentEpisodeImage" (error)="onImageError()" class="episode-img middle" />
          </div>
        </div>
        <div *ngIf="next" (click)="navigateOnClick(next)">
          <div>
            <!-- <img src="../../assets/images/new-icons/icons8-forward-50.png" class="arrow arrow-right" alt="right"> -->
            <img [src]="next.image" (error)="onImageError(next)" class="episode-img right" />
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="bottom" (click)="navigateOnClick(bottom)">
        <div>
          <img [src]="bottom.image" (error)="onImageError(bottom)" class="episode-img bottom" />
          <!-- <img src="../../assets/images/new-icons/icons8-expand-arrow-50-3.png" class="arrow arrow-bottom" alt="bottom"> -->
        </div>
      </div>
    </div>
      <!-- <div class="navigation-section mb-3 mx-3" *ngIf="nextEpisodeImage" (click)="goToNext()"> -->
        <!-- <div>
                <img [src]="nextEpisodeImage" class="episode-img" />
              </div>
              <div class="episode-box">
                <p class="episode-text">Next Episode <img src="../../assets/images/episode-left-arrow.png" /></p>
              </div> -->
        <!-- <div>
          <img [src]="nextEpisodeImage" class="episode-img" />
        </div>
        <p class="my-auto ml-3">Next Episode</p>
        <img class="my-auto ml-auto mr-4" height="20px" width="20px"
          src="../../assets/images/new-icons/icons8-right-arrow-100.png" />
      </div> -->
      <!-- </div> -->
      <!-- <div class="navigation-section m-3" *ngIf="previousEpisodeImage" (click)="goToPrevious()"> -->
        <!-- <div >
                <img [src]="previousEpisodeImage" class="episode-img" />
              </div>
              <div class="episode-box">
                <p class="episode-text" ><img src="../../assets/images/episode-right-arrow.png" /> Previous Episode</p>
              </div> -->
        <!-- <div>
          <img [src]="previousEpisodeImage" class="episode-img" />
        </div>
        <p class="my-auto ml-3">Previous Episode</p>
        <img class="my-auto ml-auto mr-4" height="20px" width="20px"
          src="../../assets/images/new-icons/icons8-right-arrow-100.png" />
      </div>
      <div class="cdk-virtual-scroll-data-source-example" *ngIf="comicData?.template == 'episodes'">
        <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="200" class="viewport"
          (scrolledIndexChange)="getNextBatch($event)">
          <div *cdkVirtualFor="let panel of comicPanels;index as i" class="example-item">
            <a (click)="getPanelAction(panel)">
              <img [src]="getImgUrl(panel)" alt="item" class="border-radius-10"
                [ngStyle]="comicIndex != i ? '' : {'opacity': '0.5'}" />
            </a>
          </div>
        </cdk-virtual-scroll-viewport>
      </div> -->


    <!-- <div> -->
  </ng-container>
</div>