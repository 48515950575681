import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  public panels: any;
  isLoading: boolean;
  @Input() notificationColumn: boolean;

  constructor(
    private readonly comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.loadPanels();
    }
  }

  public loadPanels() {
    this.isLoading = true;
    this.comicService.getHeaderChapters('/tinyview/new-user/index.json')
      .subscribe(res => {
        this.panels = res.comics.panels;
        this.isLoading = false;
      }, () => this.isLoading = false)
  }
}
