import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { ImageComponent } from '../image/image.component';
import { MenuComponent } from '../menu/menu.component';
import { ComicBonusComponent } from '../comic-bonus/comic-bonus.component';
import { ComicButtonsComponent } from '../comic-buttons/comic-buttons.component';
import { AppMaterialModule } from '../app-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { RouterModule } from '@angular/router';
import { DataNotFoundComponent } from '../shared/data-not-found/data-not-found.component';
import { StripTagsPipe } from '../shared/strip-tags.pipe';
import { OutsideClickDirective } from '../shared/directives/outside-click.directive';
import { LazySrcDirective } from '../../app/shared/directives/lazy-src.directive';
// import { SignupComponent } from '../signup/signup.component';
import { ExternalLinkComponent } from '../external-link/external-link.component';
import { ImageResizerComponent } from '../components/image-resizer/image-resizer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ComicTocComponent } from '../comic-toc/comic-toc.component';
import { ActionBarComponent } from '../action-bar/action-bar.component';
import { GiftItemsComponent } from '../gift-items/gift-items.component';
import { ActionPopupComponent } from '../action-popup/action-popup.component';
import { CommentsComponent } from '../comments/comments.component';
import { NumberFormatPipe } from '../shared/numberFormat.pipe';
import { ComicRatingComponent } from '../components/comic-rating/comic-rating.component';
import { TruncatePipe } from '../truncate.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    ImageComponent,
    ComicTocComponent,
    ActionBarComponent,
    GiftItemsComponent,
    ActionPopupComponent,
    CommentsComponent,
    NumberFormatPipe,
    ComicBonusComponent,
    ComicButtonsComponent,
    MenuComponent,
    ExternalLinkComponent,
    DataNotFoundComponent,
    StripTagsPipe,
    OutsideClickDirective,
    LazySrcDirective,
    ImageResizerComponent,
    ComicRatingComponent,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    RouterModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIntlTelInputModule,
    ImageCropperModule
  ],
  exports:[
    HeaderComponent,
    ImageComponent,
    ComicTocComponent,
    ActionBarComponent,
    GiftItemsComponent,
    ActionPopupComponent,
    CommentsComponent,
    NumberFormatPipe,
    ComicBonusComponent,
    ComicButtonsComponent,
    MenuComponent,
    DataNotFoundComponent,
    StripTagsPipe,
    LazySrcDirective,
    ExternalLinkComponent,
    // ImageResizerComponent
    ComicRatingComponent,
    TruncatePipe,
  ]
})
export class SharedModuleModule { }
