import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class SignInGuard implements CanActivate {
  currentDevice: string;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: object,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platform)) {

    // check for signin
    const isSignedInUser = this.authService.isSignedInUser();
    if (isSignedInUser) {
      // Logged in user can not view auth screens
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
  }
}
