import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ComicService } from '../services/comic.service';
import { NewComicService } from '../new-comic.service';
import { ToastrService } from 'ngx-toastr';

const COMIC_IMG_API = `${environment.API_END_POINT}`;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  currentUrl: string;
  activeStep = {
    one: false,
    two: false,
    three: false
  }
  title = {
    signup: 'Sign Up',
    signupSuccess: 'Sign Up Successful'
  }
  confirmationPanel = `${COMIC_IMG_API}/tinyview/app/tinyview-confirmation-1.jpg`;
  isSignUpSuccess: boolean;

  constructor(
    private readonly comicService: ComicService,
    private readonly newComicService: NewComicService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isSignUpSuccess = this.route.snapshot.queryParams.success;
    if (this.isSignUpSuccess) {
      this.comicService.getUserDetails().then(res => {
        const userDetails = res.data;
        if (userDetails.monthlyFreeComic > 0) {
          this.toastr.success("As a signed in user, you have unlimited access now.");
        }
      });
    }
    if (this.isSignUpSuccess) {
      setTimeout(() => {
        // Navigate to home after 10 seconds
        this.router.navigate(['/']);
      }, 10000);
    }
    this.currentUrl = this.comicService.getCurrentUrl();
    this.signupRedirect(this.currentUrl);
    this.stepStatus(this.currentUrl);

    const htmlElement = document.querySelector('html');

    // Apply styles using Renderer2
    this.newComicService.isMobileView() &&  this.renderer.setStyle(htmlElement, 'background-color', 'var(--bg-color)');
  }

  ngOnDestroy(): void {
    const htmlElement = document.querySelector('html');
    this.renderer.removeStyle(htmlElement, 'background-color');
  }

  signupRedirect(url) {
    if (url === '/signup' && !this.isSignUpSuccess) return this.router.navigate(['/signup/dob']);
  }

  stepStatus(activeStep) {
    switch (activeStep) {
      case '/signup/dob':
        this.activeStep.one = true;
        break;
      case '/signup/phone/number':
        this.activeStep.one = true;
        this.activeStep.two = true;
        break;
      case '/signup/phone/otp':
        this.activeStep.one = true;
        this.activeStep.two = true;
        break;
      case '/signup/email':
        this.activeStep.one = true;
        this.activeStep.two = true;
        break;
      case '/signup/profile':
        this.activeStep.one = true;
        this.activeStep.two = true;
        this.activeStep.three = true;
        break;
      case '/signup/email-sent':
        this.activeStep.one = true;
        this.activeStep.two = true;
    }
  }
}
