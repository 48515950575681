import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { StoryFeedService } from "../services/story-feed.service";
import { LocalStorageService } from "../services/local-storage.service";
import { FbqService } from "../services/fbq.service";
import { FBQ_TRACKING_EVENTS } from "../constants/common.constants";
import { AppService } from "../services/app.service";

@Component({
  selector: "app-gift-payment-completion",
  templateUrl: "./gift-payment-completion.component.html",
  styleUrls: ["./gift-payment-completion.component.scss"],
})
export class GiftPaymentCompletionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private feedService: StoryFeedService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private fbqService: FbqService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.appService.getGifts().then().catch();
    let giftPaymentInfo = this.localStorageService.getItem('gift_payment') || '{}';
    const paymentStatus = this.route.snapshot.queryParamMap.get("paymentStatus");
    const storyID = giftPaymentInfo && giftPaymentInfo.storyID;
    const reactionName = giftPaymentInfo && giftPaymentInfo.reactionName;
    const giftTitle =
      (reactionName &&
        reactionName
          .toLowerCase()
          .split("_")
          .map((a) => a[0] && a[0].toUpperCase() + a.slice(1))
          .join(" ")
          .trim());
    if (paymentStatus === "success") {
      this.toastr.success(
        "A notification has been sent to the creator.",
        `Thank you for gifting ${giftTitle}.`
      );
      let purchaseValue = this.localStorageService.getItem('gift_payment') || '{}';
      purchaseValue = purchaseValue ? purchaseValue.value : 0;
      this.fbqService.trackEvent(FBQ_TRACKING_EVENTS.PURCHASE, { currency: 'USD', value: purchaseValue });

    } else if (paymentStatus === "failed") {
      this.toastr.error("Your payment failed", "Failure!");
    }
    history.pushState(null, null, window.location.origin); // Overriding back button of browser with home URL

    if (storyID) {
      this.feedService.getStoryByID(storyID).then(
        (res) => {
          if (res.data.data) {
            const data = res.data.data;
            // if (reactionName) {
            //   this.feedService.doLikeStory(storyID, reactionName, data.action).then(res => {
            //     if (res.data.status === 200) {
            //       this.localStorageService.removeItem('gift_payment');
            //     }
            //   });
            // }
            if (data.actionType === 'website') {
              if (data.series) {
                this.router.navigate([data.series]);
                return;
              } else {
                this.router.navigate(['']);
                return;
              }
            }
            const comicURL = data.action.replace("/index.json", "");
            this.router.navigate([comicURL]);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
