import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComicComponent } from './comic/comic.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AuthGuard } from './services/auth.guard';
import { CreatorGuard } from './services/creator.guard';
import { SignInGuard } from './services/sign-in.guard';
import { PaymentsComponent } from './payments/payments.component';
import { subscribeURL } from './constants/common.constants';
import { GiftPaymentCompletionComponent } from './gift-payment-completion/gift-payment-completion.component';
import { ManageInfluenceComponent } from './manage-influence/manage-influence.component';
import { ComicWrapperComponent } from './pages/comic-wrapper/comic-wrapper.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SeeFriendsComponent } from './components/see-friends/see-friends.component';
import { FriendRequestComponent } from './components/friend-request/friend-request.component';
import { SignupComponent } from './signup/signup.component';
import { DobComponent } from './components/dob/dob.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SigninComponent } from './signin/signin.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EmailLoginComponent } from './components/email-login/email-login.component';
import { EmailLandingPageComponent } from './components/email-landing-page/email-landing-page.component';
import { ManageAlertsComponent } from './manage-alerts/manage-alerts.component';
import { OtpComponent } from './components/otp/otp.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { SigninSuccessComponent } from './components/signin-success/signin-success.component';
import { ChannelVerificationComponent } from './channel-verification/channel-verification.component';
import { StoryPageGuard } from './services/story-page.guard';

const routes: Routes = [
  // { path: 'story/:ID', component: ComicWrapperComponent },
  // { path: 'signup', loadChildren: () => import('../app/signin/signin.module').then(m => m.SigninModule) , canActivate: [SignInGuard] },
  // { path: 'signin', loadChildren: () => import('../app/signup/signup.module').then(m => m.SignupModule)},
  // { path: 'signin/update', loadChildren: () => import('../app/signup/signup.module').then(m => m.SignupModule)},
  // { path: 'dashboard', loadChildren: () => import('../app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  // { path: 'dashboard', loadChildren: () => import('../app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: () => import('../app/creators-dashboard/creators-dashboard.module').then(m => m.CreatorsDashboardModule), canActivate: [AuthGuard, CreatorGuard] },
  { path: 'edit', loadChildren: () => import('../app/creator-ui/creator-ui.module').then(m => m.CreatorUiModule), canActivate: [AuthGuard, CreatorGuard] },
  { path: 'notification/:comic', loadChildren: () => import('../app/notification-popup/notification-popup.module').then(m => m.NotificationPopupModule) },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent},
  // { path: 'terms-conditions', component: TermsConditionComponent},
  { path: 'subscribe', redirectTo: subscribeURL, pathMatch: 'full' },
  { path: 'payments', component: PaymentsComponent },
  { path: 'tinyview/gift-payments', component: GiftPaymentCompletionComponent },
  // { path: 'tinyview/influence-points', component: ManageInfluenceComponent},
  { path: 'Tinyview/subscribe', redirectTo: subscribeURL, pathMatch: 'full' },
  { path: 'verify-email', component: EmailLandingPageComponent },

  // { path: 'notifications', component: NotificationsComponent},
  {
    path: '', component: ComicWrapperComponent, children: [
      { path: 'manage-alerts', component: ManageAlertsComponent },
      { path: 'tinyview/manage-alerts', component: ManageAlertsComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'terms-conditions', component: TermsConditionComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'tinyview/influence-points', component: ManageInfluenceComponent },
      { path: 'story/:ID', component: ComicComponent, canActivate: [StoryPageGuard]},
      { path: 'see-friends', component: SeeFriendsComponent },
      { path: 'friend-request', component: FriendRequestComponent },

      {
        path: 'verification', component: ChannelVerificationComponent,
        children: [
          // {
          //   path: 'phone', component: SigninComponent,
          //   children: [
          //     { path: 'number', component: PhoneNumberComponent},
          //     { path: 'otp', component: OtpComponent },
          //     { path: '**', component: PhoneNumberComponent }
          //   ],
          // },
          {
            path: 'email', component: EmailLoginComponent
          },
          {
            path: 'email-sent', component: EmailLoginComponent
          },
          {
            path: '**', component: ManageAlertsComponent
          }
        ]
      },
      {
        path: 'signin', component: ChannelVerificationComponent, canActivate: [SignInGuard],
        children: [
          {
            path: 'phone', component: SigninComponent,
            children: [
              { path: 'number', component: PhoneNumberComponent},
              { path: 'otp', component: OtpComponent },
              { path: '**', component: PhoneNumberComponent }
            ]
          },
          {
            path: 'email', component: EmailLoginComponent
          },
          {
            path: 'email-sent', component: EmailLoginComponent
          },
        ]
      },
      {
        path: 'signup', component: SignupComponent,
        children: [
          { path: 'dob', component: DobComponent },
          { path: 'profile', component: ProfileComponent },
          { path: 'phone/number', component: PhoneNumberComponent },
          { path: 'phone/otp', component: OtpComponent },
          { path: 'email', component: EmailLoginComponent },
          { path: 'email-sent', component: EmailLoginComponent }
        ]
      },

      { path: 'signin-success', component: SigninSuccessComponent, canActivate: [AuthGuard] },
      { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard] },
      { path: '**', component: ComicComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const RoutingComponent = [ComicComponent, PrivacyPolicyComponent, TermsConditionComponent];
