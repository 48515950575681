import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationType } from 'src/app/constants/new-comic.interface';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { Notification, NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { isAnonymousUser } from 'src/app/utilities/common.util';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notifications: NotificationType[] = [];
  public noImageUser: string = '../../../assets/images/male-user-100.png';
  public isLoadingNotification: boolean;
  public loadingGif = "../../../assets/images/new-icons/notifications-placeholder.gif";
  public noNotificationImage: string = '../../../assets/images/new-icons/no-notification.png';

  public categorizedNotifications = {
    today: [],
    yesterday: [],
    earlier: [],
  };

  private lastNotificationID: string;
  private UID: string;
  isMobile: boolean;
  isAnonymousUser: boolean;
  public inviteFriendsRef: BsModalRef;
  isSubscribed: boolean;
  followBtnText: string;
  series = {
    action: `/index.json`,
    title: `Tinyview`
  }

  constructor(
    private comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private notificationService: NotificationService,
    private router: Router,
    private newComicService: NewComicService,
    private afAuth: AngularFireAuth,
    private modalService: BsModalService,
    private readonly userService: UserService
  ) {
    this.isAnonymousUser = isAnonymousUser();

    const user = firebase.auth().currentUser;
    this.UID = user ? user.uid : null;
    if (this.UID) {
      this.getNotificationData(this.lastNotificationID);
    }
  }
  ngOnInit() {
    this.isMobile = this.newComicService.isMobileView();

    this.newComicService.isMobileView() && this.comicService.getComicTitle().subscribe(res => {
      const seriesName = 'tinyview';
      const sideBGColor =
        (seriesName &&
          res[seriesName.replace("/", "")] &&
          res[seriesName.replace("/", "")].style &&
          res[seriesName.replace("/", "")].style.notificationColor);
      document.documentElement.style.setProperty(`--side-bg-color`, sideBGColor);
    })
    if (this.isMobile) this.bannerOnMobileView();
  }

  async bannerOnMobileView() {
    setTimeout(async () => {
      const userData = await this.userService.getUserDetails();
      const alerts = userData.data.data.alerts;
      Object.keys(alerts).includes('tinyview') ? this.followBtnText = 'Following' : this.followBtnText = 'Follow';
    }, 500);
  }
  public onBannerChange(data) {
    if (!data) return;
    this.followBtnText = data.followBtnText;
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymousUser ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }
  private getNotificationData(lastNotificationID?: string): any {
    try {
      this.isLoadingNotification = true;
      this.notificationService.getNotification(100, lastNotificationID).then(res => {
        const data = res.data
        if (data.length === 0) {
          return this.isLoadingNotification = false;
        } else if (data.length !== 0) {
          data.map(item => {
            this.notifications.push(new Notification(item));
          });
          this.categorizeNotifications(this.notifications);
          this.findOldestItem(this.categorizedNotifications);
        }
        return this.isLoadingNotification = false;
      });
    } catch (e) {
      return this.isLoadingNotification = false;
    }
  }

  private categorizeNotifications(notifications: Notification[]): any {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to start of the day

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1); // Get yesterday's date



    notifications.forEach((notification) => {
      const createdAtDate = new Date(notification.createdAt);
      createdAtDate.setHours(0, 0, 0, 0); // Set time to start of the day
      if (notification.createdAt) {
        const duration = this.comicService.getDataDiff(notification.createdAt);
        notification['duration'] = duration === 'Just now' ? `${duration}` : `${duration} ago`;
      }
      switch (true) {
        case createdAtDate.getTime() === today.getTime():
          this.categorizedNotifications.today.push(notification);
          break;
        case createdAtDate.getTime() === yesterday.getTime():
          this.categorizedNotifications.yesterday.push(notification);
          break;
        default:
          this.categorizedNotifications.earlier.push(notification);
          break;
      }
    });
  }
  private findOldestItem(categorizedNotifications) {
    let oldestItem = null;

    for (const category in categorizedNotifications) {
      const notifications = categorizedNotifications[category];

      for (const notification of notifications) {
        const createdAt = notification.createdAt;

        // Check if the current notification is older than the previously found oldest item
        if (!oldestItem || createdAt < oldestItem.createdAt) {
          oldestItem = { ...notification };
        }
      }
    }
    this.lastNotificationID = oldestItem.notificationID;
  }

  public navigateTo(uri: string) {
    this.router.navigate([uri]);
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str)
    return firstLetters;
  }

  public isUnread(timestamp) {
    // Get the current timestamp in milliseconds
    const now = Date.now();

    // Calculate the difference between the current time and the input timestamp
    const difference = now - timestamp;

    // Number of milliseconds in 24 hours
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;

    // Check if the difference is less than 24 hours
    return difference <= twentyFourHoursInMillis;
  }

  handleImageError(event: Event) {
    const targetImage = event.target as HTMLImageElement;
    targetImage.classList.remove('image-border');
    targetImage.src = '../../assets/images/male-user-100.png';
  }
}
