import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BROWSER, signInPhoneURL, signUpURL } from '../constants/common.constants';
import { NewComicService } from '../new-comic.service';
import { isAnonymousUser } from '../utilities/common.util';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-influence-card',
  templateUrl: './influence-card.component.html',
  styleUrls: ['./influence-card.component.scss', './../payments/payments.component.scss']
})
export class InfluenceCardComponent implements OnInit {
  @Input() cardData: any;
  @ViewChild('shareModal', { static: false }) shareModal;
  @ViewChild('inviteFriendsModal', { static: false }) inviteFriendsModal;
  @Input() storyAction: any;
  @Input() storyID: any;
  @Input() isWebsite?: boolean;
  shareRef: BsModalRef;
  shareConfig: { title: string; action: string; image: string; show: boolean; }[];
  isDesktop: boolean;
  ctaList: Array<string>
  isAndroid: boolean;
  currentDevice: string;
  isWindows: boolean;
  shareLink: any;
  isMobile: boolean;
  inviteFriendsRef: BsModalRef;
  isSubscribed: boolean;
  isAnonymous: boolean;
  isSubscriber: any;

  constructor(
    private comicService: ComicService,
    private newComicService: NewComicService,
    private modalService: BsModalService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isDesktop = !!(this.currentDevice == BROWSER.DESKTOP);
    this.isAndroid = !!(this.currentDevice == BROWSER.ANDROID);
    this.isWindows = this.comicService.isWindows();
    this.isMobile = this.newComicService.isMobileView();
    this.shareConfig = this.comicService.getShareItems(this.isDesktop, this.isAndroid, this.isWindows);
    this.isAnonymous = isAnonymousUser();
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
  }

  public openApp(): void {
    this.comicService.openInStore('', 'added_influence');
  }

  public manageInfluence() {
    switch (this.cardData.action) {
      case 'openApp':
        this.openApp();
        break;
      case 'share':
        // call api to get branch link
        this.openShareModal(this.shareModal);
        break;
      case 'addFriend':
        this.openApp();
        break;
      case 'signUp':
        this.router.navigate([signUpURL]);
        break;
      case 'signIn':
        this.router.navigate([signInPhoneURL]);
        break;
      case 'inviteFriends':
        this.isSubscribed = true;
        let userDetails = this.comicService.userDetails;
        if (!userDetails) {
          this.comicService.getUserDetails().then(res => {
            userDetails = res.data;
            this.openInviteFModal(userDetails);
          });
        } else {
          this.openInviteFModal(userDetails);
        }
        break;
    }
  }

  public openInviteFModal(userDetails) {
    const subscriptions = userDetails && userDetails.subscriptions
      ? userDetails.subscriptions
      : (userDetails.data && userDetails.data.subscriptions)
        ? userDetails.data.subscriptions
        : [];
    const store = subscriptions.length && subscriptions[0] && subscriptions[0].store && subscriptions[0].store.toLowerCase();
    if (!['stripe', 'apple', 'android', 'google'].includes(store)) {
      this.isSubscribed = false;
    }
    this.openInviteFriendsModal(this.inviteFriendsModal);
  }

  public manualShare(share) {
    this.comicService.shareConditions(this.shareLink, share.action, this.isDesktop, this.isAndroid);
    // this.comicService.manualShare(this.storyAction, this.storyID, share, this.isDesktop, this.isAndroid);
    this.shareRef.hide();
  }

  async openShareModal(template: TemplateRef<any>) {
    const data = await this.comicService.getInfluenceData(this.storyAction, this.storyID, this.isWebsite);
    // console.log('storyID', this.storyID);
    // console.log('actionURL', this.storyAction);
    // console.log('data', data);
    window['branch'].link(data, (err, link) => {
      this.shareLink = link;
      const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
      this.shareRef = this.modalService.show(template, Object.assign({}, {
        class: `modal-md${isMobile}`,
        ignoreBackdropClick: false,
        animation: !isMobile
      }));
    });
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }
}
