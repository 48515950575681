import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]',
})
export class NumericOnlyDirective {
  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    input.value = inputValue;
  }
}