import { Component } from "@angular/core";
import { DailyReferralAdaptor } from "src/app/adapters/daily-creator-data";
import { REFERRAL_DASHBOARD_COLUMNS } from "src/app/constants/common.constants";
import { DashboardService } from "src/app/services/dashboard.service";
import { UserService } from "src/app/services/user.service";
import { referalViewTable } from "src/app/utilities/common.util";

interface Filter {
  startDate: string;
};

@Component({
  selector: 'app-referral-dashboard',
  templateUrl: './referral-dashboard.component.html',
  styleUrls: ['./referral-dashboard.component.scss']
})
export class ReferralDashboardComponent {
  currentDate = new Date();
  userName: string;
  isLoading = false;
  tableError: string;
  monthlyAggregate = {};
  calculatedFor: string;

  public tableHead = REFERRAL_DASHBOARD_COLUMNS;

  public calculatedDailyData = [];
  calculatedDailyDataView = [];
  monthlyAggregateView: {};
  error: string;
  constructor(
    private dashboardService: DashboardService,
    private readonly userService: UserService
  ) {
    this.getUserDetail();
    this.getTableData();
  }

  private async getUserDetail() {
    const username = await this.userService.getUserDetails();

    this.userName = username && username.data && username.data.data.displayName;
  }

  private getTableData() {
    const fromDate = `${this.currentDate.getFullYear()}-${(this.currentDate.getMonth() + 1).toString().padStart(2, '0')}-01`;
    const filter: Filter = { startDate: fromDate };
    this.loadDailyData(filter);
  }
  private setViewData() {
    this.calculatedDailyDataView = [];
    this.monthlyAggregateView = {};
    this.calculatedDailyData.forEach(data => {
      const mapped = referalViewTable(data);
      mapped && this.calculatedDailyDataView.push(mapped);
    });
    this.monthlyAggregateView = referalViewTable(this.monthlyAggregate, true);
  }

  public onDataReceived(data: any) {
    const filter: Filter = {
      startDate: data.from
    };
    this.loadDailyData(filter);
  }

  private loadDailyData(filters) {
    this.monthlyAggregate = {};
    this.calculatedDailyData = [];
    this.tableError = '';
    let latestDate = 0;
    let timestamp = null;
    let dayData = null;
    this.isLoading = true;

    this.dashboardService.getReferrerDashboardData(filters).then( resp => {
      let tempRefCount = 0, tempRefAmt = 0;
      const dailyData = resp.data.dayWiseRevenue;
      const noDataCondition = (dailyData && Object.keys(dailyData) && !Object.keys(dailyData).length) || (dailyData && Object.keys(dailyData) && !Object.keys(dailyData).length) || !dailyData || !dailyData;
      if (noDataCondition) {
        this.tableError = 'No referral fees earned.';
        this.isLoading = false;
      }
      for([timestamp, dayData] of Object.entries(dailyData)) {
        const data = dayData as { totalReferral: number, totalReferralAmount: number, currency: string, createdAt: Date };
        const formattedDailyData = new DailyReferralAdaptor(data);
        this.calculatedDailyData.push(formattedDailyData);
        tempRefCount += data.totalReferral;
        tempRefAmt += data.totalReferralAmount;
      }
      const monthlyData = {
        totalReferral: tempRefCount,
        totalReferralAmount: tempRefAmt
      }
      this.monthlyAggregate = new DailyReferralAdaptor(monthlyData);
      this.calculatedDailyData = this.dashboardService.sortArrayByDate(this.calculatedDailyData);
      this.setViewData();
      this.isLoading = false;
      // keeping last date
      if (latestDate < parseInt(timestamp)) latestDate = parseInt(timestamp);
      this.calculatedFor = this.dashboardService.getMessageForDate(latestDate).displayValue;
    }
    ).catch( err => {
      this.isLoading = false;
      this.error = 'Unable to load referral revenue data, please refresh the page!';
    })
  }
}
